import React, {useState, useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom'

import {isEmptyObj} from 'ultra/helpers/utils';
import {DEFAULT_CITY} from 'ultra/const/general';
import {normalizeRoute} from 'ultra/helpers/route';
import {formResponseMessageVariables} from 'ultra/helpers/form';
import {getClientCity, getClientConfig} from 'ultra/configs/general';
import {removeEmptyValuesFromObj} from  'ultra/helpers/utils'
import {isHiddenField} from 'ultra/helpers/content';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import {post, patch} from '../../Helpers/http';
import {useGlobalCustomContext} from '../../Helpers/context'
import {getDefaultValues} from '../../Helpers/content'

import {useToasterStore} from '../../Stores/toster';
import {useBackdropPreloaderStore} from '../../Stores/backdropPreloader';

import FormValues from '../../Components/FormValues';
import AdaptiveDialog from '../../Components/Adaptive/Dialog';
import PreloaderForm from '../../Components/PreloaderForm';

import Form from './Form';

export default function FormContainer(props) {
  const {disabled, config, formActions, isEditMode, prepareData, afterSubmit, submitHandler, beforeSubmit, showSkeleton} = props;
  const {showSuccess, showError} = useToasterStore();
  const {userState} = useGlobalCustomContext();

  const formRef = useRef(null)

  // TODO: get bali from uri
  const {api} = getClientConfig(window);

  const [content, setContent] = useState();
  const [inProgress, setInProgress] = useState(false)
  const [errors, setErrors] = useState({})
  const [firstSubmit, setFirstSubmit] = useState(true)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true)

  const location = useLocation();

  const {showBackdropPreloader} = useBackdropPreloaderStore()

  useEffect(() => {
    // attach location default template
    if (config?.fields) {
      // setIsSubmitBtnDisabled(!isEmptyObj(errors));
      const initData = getDefaultValues(props.content, config, userState, isEditMode);

      Object.keys(config.fields).map(fieldId => {
        if (isHiddenField(config.fields[fieldId], initData)) {
          if (initData[fieldId]) delete initData[fieldId];
        }
      })

      setContent(initData)
      // setup data from URL if it's present in config
      // TODO: it's risky
      // someone can change here fields which are not visible for user
      // TODO: it should wark only for visible fields
      // query.forEach((value, key) => {
      //   if (config.fields[key]) {
      //     initContent[key] = value;
      //   }
      // })
    }
  }, [config?.fields, userState?.user])

  const onInitHandler = ({errors}) => {
    setIsSubmitBtnDisabled(!isEmptyObj(errors));
  }

  const onChangeHandler = ({content, errors}) => {
    // TODO: check is it works okay after this changes
    // if (firstSubmit) {
    //   setIsSubmitBtnDisabled(false);
    // }
    // else {
      console.error(errors)
      setIsSubmitBtnDisabled(!isEmptyObj(errors));
    // }

    setContent(content);
    setErrors(errors);
  }

  const onSubmitHandler = (ignoreApprove) => {
    if (firstSubmit) setFirstSubmit(false);
    if (inProgress) return;

    const trimTextFields = (data) => {
      Object.keys(config.fields).map(fieldId => {
        if (typeof data[fieldId] === 'string') {
          data[fieldId] = data[fieldId].trim();
        }
      });
      return data;
    }

    const formatValuesByType = (data) => {
      Object.keys(config.fields).map(fieldId => {
        if (data[fieldId] && config.fields[fieldId]?.type === 'email') {
          data[fieldId] = data[fieldId].toLowerCase();
        }
      });
      return data;
    }

    if (isSubmitBtnDisabled) return;

    if (config.form.confirm && !ignoreApprove) {
      setIsConfirmDialogOpen(true);
      return;
    }

    setInProgress(true)

    if (!isEmptyObj(errors)) {
      setIsSubmitBtnDisabled(true);

      setErrors(errors);

      showError({snackbarMessage: 'У формі присутні помилки'});

      console.error(errors);
      // TODO: fix, it's not scroll
      // formRef.current?.scrollIntoView({ behavior: 'smooth' });
      // make array of ref for each field, if has error, scroll to

      setInProgress(false);
      setIsConfirmDialogOpen(false);
      return;
    }

    setErrors({});
    setFirstSubmit(true);

    setIsSubmitBtnDisabled(false);
    showBackdropPreloader()

    const path = normalizeRoute(location.pathname)

    let url
    if (config.form.endpoint) {
      // TODO: CHECK where it's used // !!!!!!! revert changes woth additional parameter
      url = config.form.endpoint.includes('http') ? `${config.form.endpoint}` : `${api}${config.form.endpoint}`
      if (config.form.addCurrentPathToEndpoint) { url += path + location.search }
    } else {
      const city = getClientCity(window) || DEFAULT_CITY;
      url = `${api}/form/${city}/form${path}`
    }

    let data = removeEmptyValuesFromObj(content);
    if (prepareData) {
      data = prepareData(data)
    }
    else {
      data = formatValuesByType(trimTextFields(data));
    }

    if (beforeSubmit) beforeSubmit()

    if (submitHandler) {
      submitHandler(data);
      return;
    }

    const request = isEditMode ? patch : post

    // check permits for fields:
    // data.description = 'blb bla'
    // data._published = '1111111'
    // data._path_config = '222222'
    // data._parent = '\\biznes\\ukrayinskibiznesi'
    // data._parent = 123
    const launch = () => request(url, data)

    launch().then(async result => {
      if (afterSubmit) await afterSubmit({result, content})

      // apply template for success message
      let snackbarMessage = result.message || formResponseMessageVariables(config.form.successMessage, result);

      // can be undefined, if no redirect after submit
      let onCloseRedirect = result?.redirect ? result.redirect : config.form.onCloseRedirect
      const snackbarDuration = result?.delay ? result.delay : config.form.snackbarDuration

      showSuccess({snackbarMessage, onCloseRedirect, snackbarDuration})

      setInProgress(false);
      setIsConfirmDialogOpen(false);

      // clear state if not redirect
      if (!onCloseRedirect) {
        setContent(config.defaultValues || {})
      }
    })
    .catch((err) => {
      setInProgress(false);
      setIsConfirmDialogOpen(false);
      setIsSubmitBtnDisabled(false);
      showError({snackbarMessage: err?.message})
    });
  }

  const onApprovetHandler = () => {
    onSubmitHandler(true)
  }

  const formActionsWrapper = {
      disable: function () {
        if (formActions) formActions.disable()
        setIsSubmitBtnDisabled(true)
      },
      enable: function () {
        if (formActions) formActions.enable()
        setIsSubmitBtnDisabled(false)
      }
  }

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
  }

  if (!content) return <PreloaderForm />
  if (!config) return <PreloaderForm />
  if (showSkeleton) return <PreloaderForm />

  return <>
    <Grid container spacing={2} ref={formRef} className='FormContainer'>
      {config?.fields &&
        <Form
          disabled={inProgress || disabled}
          isEditMode={isEditMode}

          order={config?.fieldsOrder}
          fields={config?.fields}
          content={content}

          formActions={formActionsWrapper}

          errors={firstSubmit ? {} : errors}

          onInit={onInitHandler}
          onChange={onChangeHandler}
        />}

      {config?.form?.submitNotice && <Grid item xs={12}>
        <div className='submitNotice'>
          <WarningAmberIcon />
          <span>
            {config.form.submitNotice}
          </span>
        </div>
      </Grid>}

      <Grid item xs={12}>
        <div className='FormSubmitButtonWrap'>
        <div className='FormSubmitButton'>
          <Button
            fullWidth
            disabled={inProgress || disabled || isSubmitBtnDisabled}
            variant="contained"
            onClick={() => onSubmitHandler(false)}>
              {config?.form?.submitText}
          </Button>
        </div>
        </div>
      </Grid>
    </Grid>

    <AdaptiveDialog
      open={isConfirmDialogOpen}
      onClose={handleConfirmDialogClose}
      title={<>
          {/* <ShoppingCartIcon className='icon'/> */}
          {config?.form?.confirmTitle || 'Підтвердити заявку'}
          {/* {(isEmptyObj(ordersResults) || Object.keys(ordersResults).length !== cart.length) && <Button size='small' className='clear' onClick={handleClear} variant="outlined" fullWidth>
              {isMobile ? 'Очистити' : 'Очистити корзину'}
          </Button>} */}
      </>}
    >
      <div className='FormContainerConfirm'>
        {config?.form?.confirm === 'exchange-summary' && <>
          <FormValues config={config} data={content} />
          {/* <div className='details'>
            <p>Приблизний курс обміну 100 USD = 200 UAH</p>
            <p>При обміні від 1000$ курс 100 USD = 220 UAH</p>
          </div> */}
        </>}
      </div>

      <Button
        fullWidth
        variant="contained"
        onClick={onApprovetHandler}>
          Підтвердити
      </Button>
    </AdaptiveDialog>
  </>;
}

// CAPTCHA
// import Captcha from './Captcha'
// const {filters} = configs;
// let {message, photos} = getTelegramMessage(content, config.fields, config.title, filters);

// if (config.captcha) {
//   setState({...state, showCaptcha: true});
// } else {
//   saveForm(photos)
// }

//   const onCaptchaClose = () => {
//     setState({...state, showCaptcha: false, backdropOpen: false})
//   }

//   const onCaptchaSubmit = (answers) => {
//     let {message, photos} = getTelegramMessage(content, config.fields, config.title);

//     message += `
// Капча:
// `;

//     const captcha = ['','',''];

//     config.captcha.options.map((q, i) => {
//       message += `  ${q}: ${answers[i]}
// `;
//       captcha[i] = `${q}: ${answers[i]}`;
//     })

//     saveForm(photos, captcha)
//   }

//       <Backdrop
//           sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
//           open={(showCaptcha)}
//       >
//           {showCaptcha &&
//               <Captcha
//                 values={config.captcha.options}
//                 inProgress={inProgress}
//                 description={config.captcha.description}
//                 onCaptchaClose={onCaptchaClose}
//                 onCaptchaSubmit={onCaptchaSubmit} />
//           }
//       </Backdrop>
