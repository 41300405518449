import {useEffect} from 'react';
import {isMobile} from "react-device-detect";
import {useLocation, useNavigate} from "react-router-dom"; // NavLink as RouterLink, 

import Skeleton from '@mui/material/Skeleton';
import Diversity1Icon from '@mui/icons-material/Diversity1';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getCityConfig} from 'ultra/configs/general';
import {getClientDefaultImageUrl} from 'ultra/configs/general';

import {useNavigationStore} from '../../../../../../../../Stores/navigation';
import {useConfigStore} from '../../../../../../../../Stores/config';

import {setPageTitle} from '../../../../../../../../Helpers/router';
import {navigateTo} from '../../../../../../../../Helpers/loader';
import {useGlobalCustomContext} from '../../../../../../../../Helpers/context';

import {NODE_ACTIONS} from '../../../../../../Reducers/node';

import './index.scss'


export default function Template(props) {
    const {configs} = useConfigStore();
    const {contentLoading} = useNavigationStore()
    const {nodeDispatch} = useGlobalCustomContext();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setPageTitle();
    }, [location])

    if (configs.content.onMaintenance) {
        return <div className="Content">
            <div className='OnMaintenance'>Проводяться технічні роботи</div>
        </div>
    }

    if (contentLoading) return <>
        <div className="Cities">
            <div className="City">
                <span className='Wrap'>
                    <span className='country'></span>
                    <span className='city'>
                        <Skeleton variant="rectangular" width='100px' height={20} sx={{marginBottom: '1rem', marginTop: '.5rem'}} />
                    </span>
                    <span className='country'>
                        <Skeleton variant="rectangular" width='200px' height={20} />
                    </span>
                </span>
            </div>
            <div className="City">
                <span className='Wrap'>
                    <span className='country'></span>
                    <span className='city'>
                        <Skeleton variant="rectangular" width='100px' height={20} sx={{marginBottom: '1rem', marginTop: '.5rem'}} />
                    </span>
                    <span className='country'>
                        <Skeleton variant="rectangular" width='200px' height={20} />
                    </span>
                </span>
            </div>
            {!isMobile && <div className="City">
                <span className='Wrap'>
                    <span className='country'></span>
                    <span className='city'>
                        <Skeleton variant="rectangular" width='100px' height={20} sx={{marginBottom: '1rem', marginTop: '.5rem'}} />
                    </span>
                    <span className='country'>
                        <Skeleton variant="rectangular" width='200px' height={20} />
                    </span>
                </span>
            </div>}
            {!isMobile && <div className="City">
                <span className='Wrap'>
                    <span className='country'></span>
                    <span className='city'>
                        <Skeleton variant="rectangular" width='100px' height={20} sx={{marginBottom: '1rem', marginTop: '.5rem'}} />
                    </span>
                    <span className='country'>
                        <Skeleton variant="rectangular" width='200px' height={20} />
                    </span>
                </span>
            </div>}
        </div>
    </>

    const onCityClick = (e, city) => {
        e?.preventDefault();
        navigateTo(city, '/?city=' + city, navigate);
    }

    return <div className="Cities">
        {configs.content.citiesOrder.map(id => {
            if (id === DEFAULT_CITY) return;

            const city = getCityConfig(configs, id);

            if (!city) return;

            return <div className="City" key={city.city}>
                {city.inProgress && <span className='Wrap WrapInProgress' style={{backgroundImage: `url(${getClientDefaultImageUrl(window, `config/cities/${id}.jpg`)})`}}>
                    <span className='city'>{city.city}</span>
                    <span className='country'>{city.flag} {city.country}</span>
                    {city.inProgress && <span className='mode'>* в розробці</span>}
                </span>}

                {!city.inProgress && <span className='Wrap' onClick={(e) => onCityClick(e, id)} style={{backgroundImage: `url(${getClientDefaultImageUrl(window, `config/cities/${id}.jpg`)})`}}>
                    <span className='city'>{city.city}</span>
                    <span className='country'>{city.flag} {city.country}</span>
                </span>}
            </div>
        })}
        <div className="City CityAdd">
            {/* <RouterLink className='Wrap Promo' to={restorePath(configs.links.partner.uri)}> */}
            <span className='Wrap Promo' onClick={() => nodeDispatch({type: NODE_ACTIONS.POPUP_FORM, data: configs.links.partner})}>
                <Diversity1Icon />
                <span className='promo'>ДОДАТИ ВАШЕ МІСТО</span>
            </span>
            {/* </RouterLink> */}
        </div>
    </div>
}
