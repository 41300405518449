import DOMPurify from "dompurify"

import React, {useState, useMemo, useCallback} from 'react';

import Button from '@mui/material/Button';

import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {getExchangeTelegramUrl, getExchangeWhatsAppUrl, getExchangeWebMessage, getExchangeTelegramMessage, getExchangeWhatsAppMessage, getExchangeBotMessage} from 'ultra/helpers/partner';

import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';
import {useNotifyStore} from '../../../../Stores/notify';
import {useToasterStore} from '../../../../Stores/toster';

import {NODE_ACTIONS} from '../../Reducers/node';

import {useGlobalCustomContext} from '../../../../Helpers/context';

import {updateFormOrder, updateShopOrder, notifyShopOrder, notifyFormOrder} from '../../Helpers/order';

import DateValue from '../../Widgets/DateValue';
import AdaptiveDialog from '../../../../Components/Adaptive/Dialog';

export default function StatusUpdate(props) {
    const {fields, field, values, partner, hideStatus} = props;
    const {showBackdropPreloader, hideBackdropPreloader} = useBackdropPreloaderStore()
    const {nodeDispatch} = useGlobalCustomContext();
    const {showSuccess, showError} = useToasterStore();

    const {showNotify} = useNotifyStore();

    const [opened, setOpened] = useState();
    const [type, setType] = useState();
    
    const messenger = useMemo(() => {
        if (values?.data?.messenger?.telegram) return 'telegram';
        if (values?.data?.messenger?.whatsapp) return 'whatsapp';
        return undefined;
    })

    const webMessage = useMemo(() => {
        return getExchangeWebMessage(partner, values, values._oid);
    }, [values, fields, field])

    const webMessageSanitazed = useMemo(() => {        
        return DOMPurify.sanitize(getExchangeWebMessage(partner, values, values._oid));
    }, [values, fields, field])

    const telegramMessage = useMemo(() => {
        return getExchangeTelegramMessage(partner, values, values._oid);
    }, [values, fields, field])

    const whatsappMessage = useMemo(() => {
        return getExchangeWhatsAppMessage(partner, values, values._oid);
    }, [values, fields, field])

    const botText = useMemo(() => {
        return DOMPurify.sanitize(getExchangeBotMessage(partner, values, values._oid, values._has_bot));
    })

    const sendToTelegram = useCallback(() => {
        const message = getExchangeTelegramMessage(partner, values, values._oid);
        const url = getExchangeTelegramUrl(values?.data, encodeURIComponent(message));
        if (window?.Telegram?.WebApp) {
            window.Telegram.WebApp.openTelegramLink(url);
            // window.Telegram.WebApp.close();
        }
        else {
            // window.location.href = getUrl(domain, field.city, field.uri)
            // window.location.assign(getMessengerLink(messenger))
            window.open(url, '_blank');
        }
    }, [values, fields, field])

    const sendToWhatsApp = useCallback(() => {
        const message = getExchangeWhatsAppMessage(partner, values, values._oid);
        const url = getExchangeWhatsAppUrl(values?.data, encodeURIComponent(message));
        window.open(url);
    }, [values, fields, field])

    const sendToTelegramBot = useCallback((e, callback) => {
        let request
        if (values.form) request = notifyFormOrder
        else if (values.shop) request = notifyShopOrder

        e.stopPropagation();

        showBackdropPreloader();

        request(values.partner, values.form, values._oid)
            .then(() => {
                hideBackdropPreloader();
                showSuccess({snackbarMessage: "Повідомлення успішно відправлено користувачу"})
                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});
                callback();
            })
            .catch((e) => {
                hideBackdropPreloader();
                showError({snackbarMessage: e.message})
            })
    }, [values])

    const cancelType = () => {
        setType(undefined)
    }

    const onStatusUpdate = (e, status) => {
        e.stopPropagation();

        showBackdropPreloader();

        let request
        if (values.form) request = updateFormOrder
        else if (values.shop) request = updateShopOrder

        request(values.partner, values.form, values._oid, status)
            .then((result) => {
                hideBackdropPreloader();

                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});

                if (result.redirect) {
                    try {
                        if (window?.Telegram?.WebApp) {
                            window.Telegram.WebApp.openTelegramLink(result.redirect);
                            // window.Telegram.WebApp.close();
                        }
                        else {
                            // window.location.href = getUrl(domain, field.city, field.uri)
                            // window.location.assign(getMessengerLink(messenger))
                            window.open(result.redirect, '_blank');
                        }
                    }
                    catch (e) {
                        showSuccess({snackbarMessage: e.message})
                    }
                }
                else if (result.message) {
                    showSuccess({snackbarMessage: result.message})
                }
                else {
                    if (status === 'success') showSuccess({snackbarMessage: "Заявку успішно заврешено"})
                    if (status === 'canceled') showSuccess({snackbarMessage: "Заявку скасовано"})
                }
            })
            .catch((e) => {
                hideBackdropPreloader();
                showError({snackbarMessage: e.message})
            })       
    }

    {/* Контакт з користувачем */}
        {/* Замовлення зроблене з боту
            по telegram_id
            по залогіненому user_id взяти telegram_id 

        Замовлення з сайту
            по залогіненому user_id взяти telegram_id 

            якщо анонім - не можливо звʼязатись */}

    // CHECK PARTNER LEVEL
    // if (orderData?.access === 'manager' || fullAcces) return <></>

    if (values?.status === 'new') {
        let icon = <></>;
        if (values?.data?.messenger?.telegram) icon = <TelegramIcon />;
        else if (values?.data?.messenger?.whatsapp) icon = <WhatsAppIcon />;

        return <div className="StatusUpdate">
            {!hideStatus && <div className="status">
                📥 Нова заявка
            </div>}

            <div className="statusWrap">
                <Button startIcon={icon} size="small" className='color_default' fullWidth variant='outlined' onClick={(e) => onStatusUpdate(e, 'in_progress')}>Взяти в роботу</Button>
                <Button startIcon={<HighlightOffIcon />} size="small" className='color_red' fullWidth variant='outlined' onClick={(e) => onStatusUpdate(e, 'canceled')}>Скасувати</Button>
            </div>
        </div>
    }
    else if (values?.status === 'in_progress') {
        return <div className="StatusUpdate">
            {!hideStatus && <div className="status">
                🔄 Заявка в обробці
            </div>}
            
            <div className="statusWrap">
                <Button startIcon={<ChatBubbleOutlineIcon />} size="small" className='color_default' fullWidth variant='outlined' onClick={()=>{setOpened(true)}}>Повідомлення</Button>
                <Button startIcon={<CheckCircleOutlineIcon />} size="small" className='color_default' fullWidth variant='outlined' onClick={(e) => onStatusUpdate(e, 'success')}>Завершити</Button>
                <Button startIcon={<HighlightOffIcon />} size="small" className='color_red' fullWidth variant='outlined' onClick={(e) => onStatusUpdate(e, 'canceled')}>Скасувати</Button>
            </div>

            <AdaptiveDialog open={opened} onClose={()=>{setOpened(false)}} title="Повідомлення користувачу">    
                {type === 'bot' && <div>
                    {!values?._bot_notified && <div className='ExchangeMobileOrderDetailsPopup'>
                        <div className='pre' dangerouslySetInnerHTML={{ __html: botText }}></div>
                    </div>}
                    <div className='ExchangeMobileOrderDetailsActions'>
                        {!values?._bot_notified && <Button fullWidth variant='outlined' onClick={(e) => sendToTelegramBot(e, () => { setOpened(false); cancelType(); })}>Відправити</Button>}
                        <Button fullWidth variant='outlined' onClick={cancelType}>Повернутись</Button>
                    </div>
                </div>}

                {type === 'web' && <div>
                    <div className='ExchangeMobileOrderDetailsPopup'>
                        <span className="CopyIcon"><ContentCopyIcon onClick={() => { navigator.clipboard.writeText(webMessage); showNotify('Повідомлення скопійовано в буфер'); }}/></span>
                        <div className='pre' dangerouslySetInnerHTML={{ __html: webMessageSanitazed }}></div>
                    </div>
                    <div className='ExchangeMobileOrderDetailsActions'>
                        <Button fullWidth variant='outlined' onClick={cancelType}>Повернутись</Button>
                    </div>
                </div>}

                {type === 'telegram' && <div>
                    <div className='ExchangeMobileOrderDetailsPopup'>
                        <span className="CopyIcon"><ContentCopyIcon onClick={() => { navigator.clipboard.writeText(telegramMessage); showNotify('Повідомлення скопійовано в буфер'); }}/></span>
                        <div className='pre' dangerouslySetInnerHTML={{ __html: webMessageSanitazed }}></div>
                    </div>
                    <div className='ExchangeMobileOrderDetailsActions'>
                        <Button fullWidth variant='outlined' onClick={sendToTelegram}>Відправити</Button>
                        <Button fullWidth variant='outlined' onClick={cancelType}>Повернутись</Button>
                    </div>
                </div>}

                {type === 'whatsapp' && <div>
                    <div className='ExchangeMobileOrderDetailsPopup'>
                        <span className="CopyIcon"><ContentCopyIcon onClick={() => { navigator.clipboard.writeText(whatsappMessage); showNotify('Повідомлення скопійовано в буфер'); }}/></span>
                        <div className='pre' dangerouslySetInnerHTML={{ __html: webMessageSanitazed }}></div>
                    </div>
                    <div className='ExchangeMobileOrderDetailsActions'>
                        <Button fullWidth variant='outlined' onClick={sendToWhatsApp}>Відправити</Button>
                        <Button fullWidth variant='outlined' onClick={cancelType}>Повернутись</Button>
                    </div>
                </div>}

                {!type && <div className='ExchangeMobileOrderDetailsActions'>
                    {messenger === 'telegram' && <Button startIcon={<TelegramIcon />} fullWidth variant='outlined' onClick={() => { setType('telegram') }}>Телеграм</Button>}
                    {messenger === 'whatsapp' && <Button startIcon={<WhatsAppIcon />} fullWidth variant='outlined' onClick={() => { setType('whatsapp') }}>WhatsApp</Button>}
                    {!messenger && <Button startIcon={<WhatsAppIcon />} fullWidth variant='outlined' onClick={() => { setType('web') }}>Відправити повідомлення</Button>}

                    {values?._bot_notified && <div className='ExchangeMobileOrderDetailsPopup'>
                        <div><SmartToyOutlinedIcon style={{"position": "relative", "top": "5px"}} /> Нотифікація в телеграм бот була відправлена:</div>
                        <DateValue value={values?._bot_notified} />
                    </div>}

                    {!values?._bot_notified && values?._has_bot && <Button startIcon={<SmartToyOutlinedIcon />} fullWidth variant='outlined' onClick={() => { setType('bot') }}>Телеграм Бот</Button>}
                </div>}
            </AdaptiveDialog>
        </div>
    }
    else if (!hideStatus && values?.status === 'success') {
        return <div className="StatusUpdate">
            <div className="status">
                ✅ Успішно заврешено
            </div>
        </div>
    }
    else if (!hideStatus && values?.status === 'canceled') {
        return <div className="StatusUpdate">
            <div className="status statusError">
                ❌ Заявку скасовано
            </div>
        </div>
    }
    return <></>
}
