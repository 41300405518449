var _a = require('../const/general'), STORAGE_DOMAIN = _a.STORAGE_DOMAIN, DEFAULT_CITY = _a.DEFAULT_CITY;
export function getClientUrl(city, url) {
    var domain = getClientConfig(window).domain;
    return getUrl(domain, city, url);
}
export function getClientEnv(window) {
    if (window.localStorage.getItem("env")) {
        return window.localStorage.getItem("env");
    }
    if (window.location.host.includes(getEnvConfig('local').domain)) {
        return 'local';
    }
    if (window.location.host.includes(getEnvConfig('dev').domain)) {
        return 'dev';
    }
    return 'prod';
}
export function getCityFromSubdomain(window) {
    var domain = getClientConfig(window).domain;
    var splited = window.location.host.split(domain);
    if (splited.length > 1) {
        return splited[0].substring(0, splited[0].length - 1);
    }
    return null;
}
export function getClientCity(window) {
    var city = localStorage.getItem('city');
    if (city)
        return city;
    return getCityFromSubdomain(window);
}
export function getClientCityName(window) {
    var city = getClientCity(window) || DEFAULT_CITY;
    var config = getEnvConfig(getClientEnv(window));
    return config.names[city];
}
export function getClientConfig(window) {
    return getEnvConfig(getClientEnv(window));
}
export function getCityConfig(configs, city) {
    if (!(configs === null || configs === void 0 ? void 0 : configs.content))
        return {};
    return configs.content.cities[city];
}
export function getClientCityTimezone(configs, city) {
    if (city === DEFAULT_CITY) {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return getCityConfig(configs, city).tz;
}
export function getUrl(domain, city, url) {
    var protocol = 'https';
    if (city === DEFAULT_CITY)
        return "".concat(protocol, "://").concat(domain).concat(url ? url : '');
    if (city)
        return "".concat(protocol, "://").concat(city, ".").concat(domain).concat(url ? url : '');
    return null;
}
export function getEnvConfig(env) {
    var names = {
        bali: 'Балі'
    };
    switch (env) {
        case 'prod': {
            return {
                names: names,
                domain: "miy-prostir.online",
                buckets: {
                    "default": "default-images.miy-prostir.online",
                    bali: "bali-images.miy-prostir.online",
                },
                api: "https://api.miy-prostir.online/api",
                notifications: "wss://api.miy-prostir.online/notify",
                typesense: {
                    apiKey: "rzPzPyyjybUk7XlTLis4NHtBKRpc1KOlfhrxng232nZo9evG",
                },
                firebase: {
                    apiKey: "AIzaSyC8JZNofHOXCCLItHbgkXeMgIX_j4PuVpM",
                    mapId: "f2f88e980b997c19",
                    authDomain: "prostir-prod.firebaseapp.com",
                    projectId: "prostir-prod",
                    messagingSenderId: "947395099051",
                    appId: "1:947395099051:web:56ab168f33635186f73aee",
                    measurementId: "G-D89YVYH830"
                }
            };
        }
        case 'dev':
            return {
                names: names,
                domain: "test-mp.co.ua",
                buckets: {
                    "default": "default-images.test-mp.co.ua",
                    bali: "bali-images.test-mp.co.ua",
                },
                api: "https://api.test-mp.co.ua/api",
                notifications: "wss://api.test-mp.co.ua/notify",
                typesense: {
                    apiKey: "BAvepMzdzT4jwDD3HnmtSTYBP8oepZyhfuzZ13QCVHYIr3UW",
                },
                firebase: {
                    apiKey: "AIzaSyAglBqZ2RLQVQybY-J7WvTh7L7rKy4joBo",
                    mapId: "9aef86c57133fc9",
                    authDomain: "prostir-dev.firebaseapp.com",
                    projectId: "prostir-dev",
                    messagingSenderId: "360473442676",
                    appId: "1:360473442676:web:bf5e05ad82791501601421",
                    measurementId: "G-3G36RBFZEN"
                }
            };
        default: {
            return {
                names: names,
                domain: "local-miy-prostir.online",
                bucket: "images-local-miy-prostir-online",
                buckets: {
                    "default": "default-images-local-miy-prostir-online",
                    bali: "bali-images-local-miy-prostir-online",
                },
                api: "https://api.local-miy-prostir.online/api",
                notifications: "wss://api.local-miy-prostir.online/notify",
                typesense: {
                    apiKey: "BAvepMzdzT4jwDD3HnmtSTYBP8oepZyhfuzZ13QCVHYIr3UW",
                },
                firebase: {
                    apiKey: "AIzaSyAglBqZ2RLQVQybY-J7WvTh7L7rKy4joBo",
                    mapId: "9aef86c57133fc9",
                    authDomain: "prostir-dev.firebaseapp.com",
                    projectId: "prostir-dev",
                    messagingSenderId: "360473442676",
                    appId: "1:360473442676:web:bf5e05ad82791501601421",
                    measurementId: "G-3G36RBFZEN"
                }
            };
        }
    }
}
export function getDefaultImageUrl(env, uri) {
    var buckets = getEnvConfig(env).buckets;
    var path;
    if (!env || env === 'local') {
        path = "".concat(STORAGE_DOMAIN, "/").concat(buckets[DEFAULT_CITY]);
    }
    else {
        path = "https://".concat((buckets[DEFAULT_CITY]));
    }
    return path + "/".concat(uri);
}
export function getImageUrl(env, city, uri) {
    var buckets = getEnvConfig(env).buckets;
    var path;
    if (!env || env === 'local') {
        path = "".concat(STORAGE_DOMAIN, "/").concat(buckets[city]);
    }
    else {
        path = "https://".concat(buckets[city]);
    }
    return path + "/".concat(uri);
}
export function getClientDefaultImageUrl(window, uri) {
    if ((uri === null || uri === void 0 ? void 0 : uri.indexOf('blob')) > -1) {
        return uri;
    }
    ;
    if ((uri === null || uri === void 0 ? void 0 : uri.indexOf('http')) > -1) {
        return uri;
    }
    ;
    var env = getClientEnv(window);
    return getDefaultImageUrl(env, uri);
}
export function getClientImageUrl(env, city, uri) {
    if ((uri === null || uri === void 0 ? void 0 : uri.indexOf('blob')) > -1) {
        return uri;
    }
    ;
    return getImageUrl(env, city, uri);
}
