import {isUndefined, isArray, isEqual, remove} from 'lodash';

export function getActiveFilterFromURL(path = window.location.href) {
    let url = new URL(path);

    const result = {};
    const order = url.searchParams.get('order');
    if (order) {
        result.order = JSON.parse(order) 
    }

    const ranges = url.searchParams.get('ranges');
    if (ranges) {
        result.ranges = JSON.parse(ranges) 
    }

    const filters = url.searchParams.get('filters');
    if (filters) {
        result.filters = JSON.parse(filters)
    }

    const rangesSort = url.searchParams.get('rangesSort');
    if (rangesSort) {
        result.rangesSort = JSON.parse(rangesSort) 
    }

    return result;
}

export function updateActiveFilters(filters, url = window.location.href) {
    const activeFilterFromUrl = getActiveFilterFromURL(url)
    let activeFilters = activeFilterFromUrl?.filters || []

    let update = [];
    let remove = [];
    let add = [];

    let urlShouldBeUpdated = false;

    filters?.map(newValue => {
        let isAlreadyPresent
        activeFilters?.map(activeFilter => {
            if (activeFilter[0] === newValue[0]) {
                isAlreadyPresent = true;

                if (isUndefined(newValue[2]) || newValue[2] === false || newValue[2] === 'false') {
                    remove.push(newValue);
                    urlShouldBeUpdated = true;
                }
                else if (!isArray(newValue[2]) && activeFilter[2] !== newValue[2]) {
                    update.push(newValue);
                    urlShouldBeUpdated = true;
                }
                else if (isArray(newValue[2]) && !isEqual(activeFilter[2], newValue[2])) {
                    update.push(newValue);
                    urlShouldBeUpdated = true;
                }
            }
        })

        if (!isAlreadyPresent) {
            if (newValue[0] && newValue[1]) {
                urlShouldBeUpdated = true;

                add.push(newValue)
            }
        }
    })

    if (update.length > 0) {
        update.map(i => {
            const index = activeFilters.findIndex(filter => filter[0] === i[0])
            activeFilters[index] = i;
        })
    }

    if (add.length > 0) {
        activeFilters = activeFilters.concat(add);
    }

    if (remove.length > 0) {
        remove.map(i => {
            const index = activeFilters.findIndex(filter => filter[0] === i[0])
            activeFilters.splice(index, 1);
        })
    }

    return {urlShouldBeUpdated, activeFilters}
}

export function getNumberOfSelectedFilters(showFilters, defaultOrder, calculateFilterByInline) {
    let numberOfSelectedFilters = 0;
    numberOfSelectedFilters += getNumberOfSelectedRangeFilters(showFilters);

    const {order, filters} = getActiveFilterFromURL();
    
    const sortKey = order && Object.keys(order)[0];
    if (
        !showFilters?.rangeBy
        && sortKey
        && showFilters.orderBy[sortKey]
        && sortKey === Object.keys(order)[0]
        && JSON.stringify(order) !== JSON.stringify(defaultOrder)
    ) {
        numberOfSelectedFilters += 1;
    }

    if (filters) {
        const filterKeys = []
        filters.map(item => {
            if (filterKeys.includes(item[0])) return false;
            filterKeys.push(item[0])
        })

        filterKeys.map(filter => {
            if (showFilters?.filterBy?.find(f => f.id === filter)) {
                numberOfSelectedFilters += 1;
            }
            if (calculateFilterByInline && showFilters?.filterByInline?.find(f => f.id === filter)) {
                numberOfSelectedFilters += 1;
            }
        })
    }

    return numberOfSelectedFilters;
}

export function getNumberOfSelectedRangeFilters(showFilters) {
    let numberOfSelectedFilters = 0;

    const {ranges} = getActiveFilterFromURL();

    if (ranges) {
        const rangeKeys = []
        ranges.map(item => {
            if (rangeKeys.includes(item[0])) return false;
            rangeKeys.push(item[0])
        })
        if (rangeKeys.length > 1) console.error('More then 1 range parameter')

        if (showFilters.rangeBy?.[rangeKeys[0]]) {
            numberOfSelectedFilters += 1;
        }
    }

    return numberOfSelectedFilters;
}

export function clearFilters(filterBy) {
    let url = new URL(window.location.href);

    // const nodes = configs.content.nodes;
    // const filterByList = getFiltersFieldsConfig(filterBy, children, nodes)

    // const filtersToLeave = []
    // activeFilters.map(f => {
    //     const isPresentInFilterByList = filterByList.find(i => i.id === f[0])
    //     if (!isPresentInFilterByList) {
    //         filtersToLeave.push(f)
    //     }
    // })

    // if (filtersToLeave.length > 0) {
    //     url.searchParams.set('filters', JSON.stringify(filtersToLeave.filter(f => f[2] !== '')));
    // } else {
    //     url.searchParams.delete('filters');
    // }

    let savedFilters = sessionStorage.getItem("filters");

    if (savedFilters) {
        savedFilters = JSON.parse(savedFilters);
        savedFilters = remove(savedFilters, n => !Boolean(filterBy.find(filter => filter.id === n[0])));

        if (savedFilters.length === 0) {
            sessionStorage.removeItem("filters");
        }
        else {
            sessionStorage.setItem("filters", JSON.stringify(savedFilters));
        }
    }
    url.searchParams.delete('filters');

    url.searchParams.delete('ranges');
    url.searchParams.delete('rangesSort');

    url.searchParams.delete('page');
    url.searchParams.delete('perPage');

    return url
}

export function getFiltersFieldsConfig(filterBy, children, nodes) {
    // const filtersConfig = [];

    // // show only general for all children fields

    // const filterdChildren = children?.filter(i => !['alias', 'shop-alias'].includes(i));

    // filterBy?.map(filter => {
    //     let filterConfig;
    //     filterdChildren?.map(nodeType => {
    //         if (nodes[nodeType].fields[filter.id]) {
    //             filterConfig = nodes[nodeType].fields[filter.id];
    //             delete filterConfig.required;  
    //         }
    //     })

    //     if (filter.id === '_type') {
    //         const values = {};
            
    //         Object.keys(nodes).map(id => {
    //             if (filterdChildren.includes(id)) {
    //                 values[id] = nodes[id].title
    //             }
    //         })

    //         filtersConfig.push({
    //             id: filter.id,
    //             field: {
    //                 ...filterConfig,
    //                 type: 'filter',
    //                 // placeholder: "Тип події",
    //                 values: {
    //                     values
    //                 },
    //             }
    //         })
    //     }
    //     else {
    //         filtersConfig.push({
    //             id: filter.id,
    //             field: filterConfig
    //         })
    //     }
    // });

    // return filtersConfig;
}
