import {useConfigStore} from '../../../../../../../../Stores/config';
import {useNavigationStore} from '../../../../../../../../Stores/navigation';

import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import TopPagePicture from '../../../../../../Widgets/TopPagePicture'
import FilterChip from '../../../../../../Widgets/FilterChip'
import LinksTile from '../../../../../../Widgets/LinksTile'
import Links from '../../../../../../Widgets/Links'

import './index.scss'

export default function Template(props) {
    const {content, config, children, widgets, permits, breadcrumbs} = props

    const {configs} = useConfigStore();
    const {contentLoading} = useNavigationStore()

    let hasCategories = false
    children.order.map(id => {
        if (hasCategories) return;

        if (children.list[id].category) {
            hasCategories = true;
        }
    })

    return <div className='LibraryListTemplate'>
        <TopPagePicture cutHeightForDesktop image={content.banner} />
        {/* showSkeleton={contentLoading}  */}

        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {content.showPagination &&
            <LinksTile
                // className="childrensListLinksLines"
                showSkeleton={contentLoading}
                showPagination={content.showPagination}
                // showFilters={content.showFilters}
                imgLoading={config?.templateOptions?.imgLoading}

                links={children}
                xs={6}
                md={2}

                src="poster"
                label={(link) => <FilterChip value={link.region} config={configs.content.nodes['book'].fields.region} />}
                title={(link) => <div>
                    {link.author && <div className='bookAuthors'>{link.author}</div>}
                    {link.title && <div className='bookTitle'>{link.title}</div>}
                </div>}
            />
        }

        <Links className="nodesWidget" links={widgets.nodes} showSkeleton={contentLoading} />
    </div>
}
