import {useEffect, useState} from 'react';

import Skeleton from '@mui/material/Skeleton';

import {useGlobalCustomContext} from '../../../../Helpers/context';

import {getCachedUser} from '../../../Profile/Helpers/user';

import {USER_ACTIONS} from '../../../Profile/Reducers/user';

import MessageLink from '../MessageLink';

import './index.scss'

export default function UserMessageLink(props) {
    const {variant, loading, label, id, onClick, fullWidth} = props;

    const {userState, userDispatch} = useGlobalCustomContext();

    const [account, setAccount] = useState();

    useEffect(() => {
        if (id) {
            const cachedUserAccount = userState?.ids[id];

            if (cachedUserAccount) {
                console.log('get from cache')
                setAccount({...cachedUserAccount})
            }
            else {
                console.log(id)
                getCachedUser(id)
                    .then((acc) => {
                        // const result = {};
                        // result[uid] = acc;
                        userDispatch({type: USER_ACTIONS.SET_USER_BY_ID, data: {[id]: acc}});
                        setAccount(acc);
                    })
            }
        }
        else {
            setAccount({});
        }
    }, [id])

    if (loading || !account) {
        if (variant && variant === 'button') {
            return <Skeleton variant="rectangular" width={'100%'} height={56} className='MuiButtonBase-root'/>
            // variant={{}}
        } else {
            return <Skeleton variant="rectangular" width={'122px'} height={12} />
            // variant={{display: 'inline-block'}}
        }
    }

    // if (account && isEmptyObj(account)) {
    //     return <>дані відсутні</>
    // }

    return <MessageLink
        variant={variant}
        onClick={onClick}
        loading={loading}
        fullWidth={fullWidth}
        label={label || account.displayName}
        messenger={account.messenger}
        id={account.id}
    />
}
