import {isString} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useNavigation} from "react-router-dom";
import Chip from '@mui/material/Chip';
import {useNavigate} from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

import FilterValue from '../FilterValue';
import {getActiveFilterFromURL, updateActiveFilters} from '../../Helpers/filters';

import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import './index.scss';

export default function SelectedFilters(props) {
    const {config} = props;

    const navigate = useNavigate();
    const navigation = useNavigation();

    const {showBackdropPreloader} = useBackdropPreloaderStore();

    const [filters, setFilters] = useState();

    const onDeleteHandle = (id, value) => {
        let url = new URL(window.location.href);

        const {filters} = getActiveFilterFromURL()

        if (url.search) {
            let index = filters.findIndex((f) => f[0] === id);
            const activeFilter = (filters[index] && isString(filters[index][2])) ? JSON.parse(filters[index][2]) : filters[index][2];
            const vIndex = activeFilter.indexOf(value);
            if (vIndex > -1) activeFilter.splice(vIndex, 1);
            if (activeFilter.length > 0) filters[index][2] = activeFilter;
            else filters[index][2] = undefined;
        }

        const {activeFilters} = updateActiveFilters(filters)
        const updatedFilters = activeFilters.filter(f => f[0] && f[1]);
        const str = updatedFilters.length > 0 ? JSON.stringify(updatedFilters) : undefined;

        if (updatedFilters?.length > 0) {
            url.searchParams.set('filters', str);
            sessionStorage.setItem("filters", str);
        }
        else {
            url.searchParams.delete('filters');
            sessionStorage.removeItem("filters");
        }

        showBackdropPreloader();
        navigate(url.pathname + url.search);
        // setFilters(updatedFilters);
    }

    useEffect(() => {
        const {filters} = getActiveFilterFromURL();

        const f = filters?.map(f => {
            const v = isString(f[2]) ? JSON.parse(f[2]) : f[2];
            const c = config.showFilters.filterBy.filter(i => i.id === f[0])
            return {id: f[0], values: v, config: c[0]?.field}
        });

        setFilters(f);
    }, [navigation])

    if (!filters) return <></>

    return <div className="SelectedFilters">
        {filters.map(f => f.values.map(i => <Chip
                key={`filter_${f?.config?.id}_${i}`}
                deleteIcon={<CloseIcon />}
                onDelete={() => onDeleteHandle(f?.id, i)}
                label={<FilterValue value={i} config={f.config}/>}
                variant="outlined"
            />)
        )}
    </div>
}
