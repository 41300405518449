import React, {useEffect, useState} from 'react';

import {useLoaderData, useNavigation, useParams} from "react-router-dom";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {useNavigate} from "react-router-dom";
import {getCityFromSubdomain} from 'ultra/configs/general';
import {restorePath} from "ultra/helpers/route";

import {useGlobalCustomContext} from '../../../../../Helpers/context';
import {setPageTitle} from '../../../../../Helpers/router';
import {navigateTo} from '../../../../../Helpers/loader';
import {validateOrder} from '../../../Helpers/history';

import PreloaderTable from '../../../../../Components/PreloaderTable';

import InlineActions from '../../../../Content/Widgets/InlineActions';
import TitleWidget from '../../../../Content/Widgets/Title';
import DateValue from '../../../../Content/Widgets/DateValue';
import StatusUpdate from '../../../../Content/Widgets/StatusUpdate';

import {NODE_ACTIONS} from '../../../../Content/Reducers/node';

import FormValues from '../../../../../Components/FormValues';

import {useNotifyStore} from '../../../../../Stores/notify';
import {useBackdropPreloaderStore} from '../../../../../Stores/backdropPreloader';

import './index.scss';

export default () => {
    const initData = useLoaderData();

    const navigation = useNavigation();
    const navigate = useNavigate();

    let {partner, type, order} = useParams();

    const [orderData, setOrderData] = useState();
    const [actions, setActions] = useState([])

    const {nodeState, nodeDispatch} = useGlobalCustomContext();
    const {hideBackdropPreloader} = useBackdropPreloaderStore();

    const {showNotify} = useNotifyStore();

    useEffect(() => {
        setPageTitle('Історія замовленя')

        const actions = []
        actions.push({
            id: 'copy',
            icon: <span className='ContentCopyIconWrap'><ContentCopyIcon className='Icon' /></span>,
            onClick: () => {
                navigator.clipboard.writeText(document.querySelector('.pageTitle').innerText.substring(3));
                showNotify('Номер заявки скопійовано в буфер');
            }
        })

        setActions(actions)
    }, [])
    
    useEffect(() => {
        if (navigation.state === 'idle') {
            updateOrder(initData);
        }
    }, [navigation])

    useEffect(() => {
        if (nodeState.updatePageContentStart) {
            nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_END});
            updatePage()
        }
    }, [nodeState])

    const updatePage = () => {
        updateOrder({});

        validateOrder(type, partner, order)
            .promise
            .then(updateOrder)
    }

    function updateOrder(data) {
        setOrderData({...data})
        hideBackdropPreloader()
    }

    const goToService = () => {
        navigateTo(orderData.order._city, restorePath(orderData.product.link), navigate);
    }

    const goToAdmin = () => {
        navigateTo(orderData.order._city, `/profile/history/${type}/${partner}/${orderData.order[type]}`, navigate);
    }

    const goToConversation = () => {
        navigateTo(getCityFromSubdomain(window), `/profile/messages/${orderData.cid}`, navigate);                        
    }

    return <div id="Content" className='Content'>
            <div className='HistoryValidateOrderPage'>
                {!orderData && <PreloaderTable/>}

                {orderData?.order && <TitleWidget
                    permits={{}}
                    content={{title: '📄 ' + orderData.order._oid}}
                    config={{}}
                    actions={<InlineActions unfolded actions={actions} />}
                />}

                {orderData?.product && <div className="labelWrap">
                    <div className="label">
                        Сервіс:
                    </div>
                    <div className="labelValue">
                        <span className="pseudoLink" onClick={goToService}>{orderData.product.title}</span>
                        {orderData?.access && <>
                            &nbsp;
                            (<span className="pseudoLink" onClick={goToAdmin}>Адмін панель</span>)
                        </>}
                    </div>                    
                </div>}

                {orderData?.order && <div className="labelWrap labelWrapRow">
                    {!orderData.access && <div className="label">
                        Ви оформили заявку:
                    </div>}
                    <div className="labelValue">
                        <DateValue value={orderData.order._timestamp} />
                    </div>                    
                </div>}

                {orderData?.order && <div className="labelWrap">
                    <div className="label">
                        Статус:
                    </div>
                    <div className="labelValue">
                        {orderData.order.status === 'new' && '📥 Нова заявка'}
                        {orderData.order.status === 'in_progress' && '🔄 Заявка в обробці'}
                        {orderData.order.status === 'success' && '✅ Успішно заврешено'}
                        {orderData.order.status === 'canceled' && '❌ Заявку скасовано'}
                    </div>                    
                </div>}

                {orderData?.order && <div className="labelWrap labelWrapRow">
                    <div className="label">
                        Замовлення:
                    </div>
                    <div className="labelValue">
                        <FormValues config={orderData.product.config} data={orderData.order.data} ignore={['messenger']} />
                    </div>                    
                </div>}

                {orderData?.user && <div className="labelWrap">
                    <div className="label">
                    Замовив:
                    </div>
                    <div className="labelValue">
                        {orderData.user.displayName}
                    </div>                    
                </div>}

                {!orderData?.access && orderData?.cid && <div className="labelWrap">
                    <div className="labelValue">
                        <span className="pseudoLink" onClick={goToConversation}>Історія заявок</span>
                    </div>                    
                </div>}

                {orderData?.access && <>
                    <div className="labelWrap">
                        <div className="label">
                            Рівень вашого доступу:
                        </div>
                        <div className="labelValue">
                            {orderData.access === 'admin' && <>Адміністратор PROSTIR</>}
                            {orderData.access === 'owner' && <>Власник сервісу</>}
                            {orderData.access === 'access' && <>Перегляд історії</>}
                            {orderData.access === 'manager' && <>Менеджер</>}
                        </div>
                    </div>
                </>}

                {orderData?.config && <StatusUpdate hideStatus fields={orderData.config.fields} field={"status"} values={orderData.order} partner={orderData.config} />}
            </div>
        </div>
}
