import {json, redirect} from "react-router-dom";

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientUrl, getClientCity} from 'ultra/configs/general';
// getUrl // getClientConfig
import {getUsers} from './Helpers/admin';
import {getContent} from './Helpers/content';
import {getLibrary} from './Helpers/library';
import {getMessages} from './Helpers/messages';
import {getConversation} from './Helpers/conversations';
import {getHistory} from './Helpers/history';
import {getDocumentHistory} from './Helpers/history';
import {getFormHistory, getShopHistory, getFormOrderHistory, getShopOrderHistory, validateOrder} from './Helpers/history';
import {getUserHistory, getUserDetails} from './Helpers/user';
import {getNetworking} from './Helpers/networking';

import Profile from './index';
import {checkRedirect} from '../../Helpers/loader'

import Users from './Pages/Users';
import Library from './Pages/Library';
import Networking from './Pages/Networking';
import Conversations from './Pages/Conversations';
import Conversation from './Pages/Conversation';
import Partnership from './Pages/Partnership';
import History from './Pages/History';
import HistoryNode from './Pages/History/Node';
import HistoryUser from './Pages/History/User';
import HistoryForm from './Pages/History/Form';
import HistoryFormOrder from './Pages/History/FormOrder';
import HistoryShop from './Pages/History/Shop';
import HistoryShopOrder from './Pages/History/ShopOrder';
import HistoryValidateOrder from './Pages/History/ValidateOrder';
import ValidateErrorPage from './Pages/History/ValidateErrorOrder';
import Content from './Pages/Content';

let controllers = []

function checkPathAndRedirect(pathname, search) {
    // redirect to main domain
    const onlyIndexDomainPages = [
        // '/profile/messages',

        '/profile/partnership',
        '/profile/users',

        '/profile/history',
        '/profile/history/node/',
        '/profile/history/user/',

        '/profile/history/form/',
        '/profile/history/shop/',
        '/profile/history/validate/',
    ]

    if (getClientCity(window) && onlyIndexDomainPages.includes(pathname)) {
        console.log(getClientUrl(DEFAULT_CITY, pathname + search))
        // window.location.assign(getClientUrl(DEFAULT_CITY, pathname + search));
        // return null;
    }

    return true;
}

const profileLoader = async ({request}) => {
    const url = new URL(request.url);
    const {pathname, search} = url;

    if (!checkPathAndRedirect(pathname, search)) {
        return json({}, { status: 200 });
    }

    // logged user
    try {
        const user = await getUserDetails().promise
        switch (pathname) {
            default:
                return json(user, { status: 200 });
        }
    }
    // no logged user
    catch (e) {
        switch (pathname) {
            case '/profile':
            case '/profile/edit':
                window.location.assign('/profile/login');
                return null;
            default:
                return json(null, { status: 200 });
        }
    }
}

const usersLoader = async ({request}) => {
    controllers.map(c => { c.abort(); })

    const url = new URL(request.url);

    const {pathname, search} = url;

    if (!checkPathAndRedirect(pathname, search)) {
        return json({}, { status: 200 });
    }

    const {promise, controller} = getUsers(url.search)
    controllers.push(controller)

    return promise  
}

const conversationsLoader = async ({request, params}) => {
    controllers.map(c => { c.abort(); })

    const url = new URL(request.url);

    const {pathname, search} = url;

    if (!checkPathAndRedirect(pathname, search)) {
        return json({}, { status: 200 });
    }

    const {promise, controller} = getConversation(params.cid, url.search)
    controllers.push(controller)

    return new Promise((resolve, reject) => {
        promise
            .then((data) => {
                data.order = data.order.reverse();
                resolve(data)
            })
            .catch(reject)
    })  
}

const messagesLoader = async ({request}) => {
    controllers.map(c => { c.abort(); })

    const url = new URL(request.url);

    const {pathname, search} = url;

    if (!checkPathAndRedirect(pathname, search)) {
        return json({}, { status: 200 });
    }
    
    const {promise, controller} = getMessages(url.search)
    controllers.push(controller)

    return promise  
}

const partnershipLoader = async ({request}) => {
    controllers.map(c => { c.abort(); })

    const url = new URL(request.url);

    const {pathname, search} = url;

    if (!checkPathAndRedirect(pathname, search)) {
        return json({}, { status: 200 });
    }

    const {promise, controller} = getUserDetails()
    controllers.push(controller)

    return promise  
}

const historyLoader = async ({request}) => {
    controllers.map(c => { c.abort(); })

    const url = new URL(request.url);

    const {pathname, search} = url;

    if (!checkPathAndRedirect(pathname, search)) {
        return json({}, { status: 200 });
    }

    const {promise, controller} = getHistory(url.search)
    controllers.push(controller)

    return promise  
}

const formHistoryLoader = async ({request, params}) => {
    controllers.map(c => { c.abort(); })

    const url = new URL(request.url);

    const {pathname, search} = url;

    if (!checkPathAndRedirect(pathname, search)) {
        return json({}, { status: 200 });
    }

    const {promise, controller} = getFormHistory(params.partner, params.form, url.search)
    controllers.push(controller)

    return promise
}

const formHistoryOrderLoader  = async ({request, params}) => {
    controllers.map(c => { c.abort(); })

    const url = new URL(request.url);

    const {pathname, search} = url;

    if (!checkPathAndRedirect(pathname, search)) {
        return json({}, { status: 200 });
    }

    const {promise, controller} = getFormOrderHistory(params.partner, params.form, params.order, url.search)
    controllers.push(controller)

    return promise
}

const shopHistoryLoader = async ({request, params}) => {
    controllers.map(c => { c.abort(); })

    const url = new URL(request.url);

    const {pathname, search} = url;

    if (!checkPathAndRedirect(pathname, search)) {
        return json({}, { status: 200 });
    }

    const {promise, controller} = getShopHistory(params.partner, params.shop, url.search)
    controllers.push(controller)

    return promise
}

const shopHistoryOrderLoader = async ({request, params}) => {
    controllers.map(c => { c.abort(); })

    const url = new URL(request.url);

    const {pathname, search} = url;

    if (!checkPathAndRedirect(pathname, search)) {
        return json({}, { status: 200 });
    }

    const {promise, controller} = getShopOrderHistory(params.partner, params.shop, params.order, url.search)
    controllers.push(controller)

    return promise
}

const formHistoryValidateOrderLoader = async ({request, params}) => {
    controllers.map(c => { c.abort(); })

    const url = new URL(request.url);

    const {pathname, search} = url;

    if (!checkPathAndRedirect(pathname, search)) {
        return json({}, { status: 200 });
    }

    const {promise, controller} = validateOrder(params.type, params.partner, params.order)
    controllers.push(controller)

    return promise
}

const documentHistoryLoader = async ({request, params}) => {
    controllers.map(c => { c.abort(); })

    const url = new URL(request.url);

    const {pathname, search} = url;

    if (!checkPathAndRedirect(pathname, search)) {
        return json({}, { status: 200 });
    }

    const {promise, controller} = getDocumentHistory(params.city, params.did, url.search)
    controllers.push(controller)

    return promise  
}

const userHistoryLoader = async ({request, params}) => {
    controllers.map(c => { c.abort(); })

    const url = new URL(request.url);

    const {pathname, search} = url;

    if (!checkPathAndRedirect(pathname, search)) {
        return json({}, { status: 200 });
    }

    const {promise, controller} = getUserHistory(params.id, url.search)
    controllers.push(controller)

    return promise  
}

const contentLoader = async ({request}) => {
    controllers.map(c => { c.abort(); })

    const url = new URL(request.url);
    const redirectURL = await checkRedirect(request);
    if (redirectURL) return redirect(redirectURL);

    const city = getClientCity(window) || DEFAULT_CITY;

    const {promise, controller} = getContent(city, url.search)
    controllers.push(controller)

    return promise  
}

const libraryLoader = async ({request}) => {
    controllers.map(c => { c.abort(); })

    const url = new URL(request.url);
    const city = getClientCity(window) || DEFAULT_CITY;

    const {promise, controller} = getLibrary(city, url.search)
    controllers.push(controller)

    return promise  
}

const networkingLoader = async ({params, request}) => {
    controllers.map(c => { c.abort(); })

    const url = new URL(request.url);
    const city = getClientCity(window) || DEFAULT_CITY;

    const {promise, controller} = getNetworking(city, url.search)
    controllers.push(controller)

    return promise  
}

export const routes = [
    {
        path: 'profile',
        children: [
            {
                index: true,
                element: <Profile />,
                loader: profileLoader
            },
            {
                path: "*",
                element: <Profile/>,
                loader: profileLoader
            },
            {
                path: "content",
                element: <Content/>,
                loader: contentLoader
                // TODO: admin panel has no config in DB for filters, to check is array present

                // HOW TO SOLVE THAT ?

                // import {contentLoader} from '../../Helpers/loader';
                // loader: contentLoader(controllers, getContent)
            },
            {
                path: "library",
                element: <Library />,
                loader: libraryLoader
            },
            {
                path: "networking",
                element: <Networking />,
                loader: networkingLoader
            },
            {
                path: "users",
                element: <Users />,
                loader: usersLoader
            },
            {
                path: "messages",
                children: [
                    {  
                        index: true,
                        element: <Conversations />,
                        loader: messagesLoader,
                    },
                    {
                        path: ":cid",
                        element: <Conversation />,
                        loader: conversationsLoader,
                    },
                ]
            },
            {
                path: "partnership",
                element: <Partnership />,
                loader: partnershipLoader
            },
            {
                path: "history",
                element: <History />,
                loader: historyLoader
            },
            {
                path: "history",
                children: [
                    {
                        path: "user/:id",
                        element: <HistoryUser />,
                        loader: userHistoryLoader
                    },
                    {
                        path: "node/:city/:did",
                        element: <HistoryNode />,
                        loader: documentHistoryLoader
                    },
                    {
                        path: "form/:partner/:form",
                        element: <HistoryForm />,
                        loader: formHistoryLoader
                    },
                    {
                        path: "form/:partner/:form/:order",
                        element: <HistoryFormOrder />,
                        loader: formHistoryOrderLoader
                    },
                    {
                        path: "shop/:partner/:shop",
                        element: <HistoryShop />,
                        loader: shopHistoryLoader
                    },
                    {
                        path: "shop/:partner/:shop/:order",
                        element: <HistoryShopOrder />,
                        loader: shopHistoryOrderLoader
                    },
                    {
                        path: "validate/:partner/:type/:order",
                        element: <HistoryValidateOrder />,
                        errorElement: <ValidateErrorPage />,
                        loader: formHistoryValidateOrderLoader
                    },
                ]
            },
        ]
    },
]
