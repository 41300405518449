import {cloneDeep, isUndefined} from 'lodash';
import {redirect} from "react-router-dom";

import {normalizeRoute} from 'ultra/helpers/route';
import {DEFAULT_CITY} from 'ultra/const/general';
import {getUrl, getClientCity, getClientConfig, getClientUrl} from 'ultra/configs/general';

import {updateActiveFilters} from '../Modules/Content/Helpers/filters';

import {getPathByDID} from './content';
import {getTelegramMode} from './telegram';

export const checkRedirect = (request) => new Promise(async resolve => {
    const url = new URL(request.url);

    // GO TO DID
    let params = url.searchParams;
    // let params = new URL(window.location.href).searchParams;
    let goToDid
    let goToCid
    let goToLogin
    let goToOrder
    let checkOrder
    let goToProfile
    let goToProfileNetworking
    let goToProfileContent
    let goToProfileLibrary

    if (params.get("tgWebAppStartParam")) {
        const p = params.get("tgWebAppStartParam");
        const v = p.split('---');

        document.body.classList.add('telegram');

        // ?tgWebAppStartParam=goToDid---default___l35rkAdRWCasblUdjV8U
        if (v[0] === 'goToDid') {
            goToDid = v[1].replaceAll('___', ':');
        }

        // ?tgWebAppStartParam=goToCid---l35rkAdRWCasblUdjV8U___l35rkAdRWCasblUdjV8U
        else if (v[0] === 'goToCid') {
            goToCid = v[1].replaceAll('___', ':');
        }

        // ?tgWebAppStartParam=goToOrder---form___partner___product___orderid
        else if (v[0] === 'goToOrder') {
            goToOrder = v[1].replaceAll('___', ':');
        }

        // ?tgWebAppStartParam=checkOrder---form___partner___product___orderid
        else if (v[0] === 'checkOrder') {
            checkOrder = v[1].replaceAll('___', ':');
        }

        // ?tgWebAppStartParam=goToLogin
        else if (v[0] === 'goToLogin') {
            goToLogin = true;
        }

        // ?tgWebAppStartParam=goToProfile
        else if (v[0] === 'goToProfile') {
            goToProfile = true;
        }

        // ?tgWebAppStartParam=goToProfileNetworking
        else if (v[0] === 'goToProfileNetworking') {
            goToProfileNetworking = true;
        }

        // ?tgWebAppStartParam=goToProfileContent
        else if (v[0] === 'goToProfileContent') {
            goToProfileContent = true;
        }

        // ?tgWebAppStartParam=goToProfileLibrary
        else if (v[0] === 'goToProfileLibrary') {
            goToProfileLibrary = true;
        }
    }
    else {
        if (params.get("goToDid")) {
            goToDid = params.get("goToDid");
        }

        else if (params.get("goToCid")) {
            goToCid = params.get("goToCid");
        }

        else if (params.get("goToOrder")) {
            goToOrder = params.get("goToOrder");
        }

        else if (params.get("checkOrder")) {
            checkOrder = params.get("checkOrder");
        }

        // ?goToLogin=true
        else if (params.get("goToLogin")) {
            goToLogin = true;
        }

        // ?goToProfile=true
        else if (params.get("goToProfile")) {
            goToProfile = params.get("goToProfile");
        }

        // ?goToProfileNetworking=true
        else if (params.get("goToProfileNetworking")) {
            goToProfileNetworking = params.get("goToProfileNetworking");
        }

        // ?goToProfileContent=true
        else if (params.get("goToProfileContent")) {
            goToProfileContent = params.get("goToProfileContent");
        }

        // ?goToProfileLibrary=true
        else if (params.get("goToProfileLibrary")) {
            goToProfileLibrary = params.get("goToProfileLibrary");
        }
    }

    const {domain} = getClientConfig(window);

    if (goToDid) {
      const linkDetails = goToDid.split(':');
      if (linkDetails.length !== 2) {
        console.error('Wrong link format');
      }
      else {
        const city = linkDetails[0];
        const did = linkDetails[1];

        try {
            const link = await getPathByDID(city, did).promise;
            return resolve(getUrl(domain, city, link))
        }
        catch(e) {}
      }
    }

    if (goToCid) {
        const linkDetails = goToCid.split(':');

        const conversation = linkDetails[0];
        const message = linkDetails[1];

        try {
            let url = `/profile/messages/${conversation}`
            if (message) url += `?mid=${message}`
            alert(url)
            return resolve(getUrl(domain, DEFAULT_CITY, url))
        }
        catch(e) {}
    }

    if (goToLogin) {
        return resolve(getUrl(domain, DEFAULT_CITY, '/profile/login'))
    }

    if (goToOrder) {
        const linkDetails = goToOrder.split(':');

        const type = linkDetails[0];
        const partner = linkDetails[1];
        const product = linkDetails[2];
        const link = `/profile/history/${type}/${partner}/${product}`;

        const oid = linkDetails[3];
        if (oid) link += `/${oid}`;

        return resolve(getUrl(domain, DEFAULT_CITY, link));
    }

    if (checkOrder) {
        const linkDetails = checkOrder.split(':');

        const type = linkDetails[0];
        const partner = linkDetails[1];
        const oid = linkDetails[2];
        let path = `/${partner}/${type}/${oid}`;
        let link = '/profile/history/validate' + path;

        return resolve(getUrl(domain, DEFAULT_CITY, link));
    }

    if (goToProfile) {
        const city = getClientCity(window);
        return resolve(getUrl(domain, city, '/profile'))
    }

    if (goToProfileNetworking) {
        const city = getClientCity(window);
        return resolve(getUrl(domain, city, '/profile/networking'))
    }

    if (goToProfileContent) {
        const city = getClientCity(window);
        return resolve(getUrl(domain, city, '/profile/content'))
    }

    if (goToProfileLibrary) {
        const city = getClientCity(window);
        return resolve(getUrl(domain, city, '/profile/library'))
    }

    resolve(undefined)
})

export const getNodeContentLoader = (controllers, getContent) => async ({request}) => {
    controllers.map(c => {c.abort()});

    const prevUrl = window.location.pathname;
    const prevDID = document?.getElementById('page_did')?.innerText;

    const url = new URL(request.url);
    const redirectURL = await checkRedirect(request);
    if (redirectURL) return redirect(redirectURL);

    // GET FILTERS FROM URL AND STORAGE
    let urlFilters = url.searchParams.get("filters");
    if (urlFilters) {
        urlFilters = JSON.parse(urlFilters);
    }

    let savedFilters = sessionStorage.getItem("filters");
    if (savedFilters) {
        savedFilters = JSON.parse(savedFilters);
    }

    // merge filters
    let mergedFilters = savedFilters ? cloneDeep(savedFilters) : [];
    if (urlFilters) {
        urlFilters.map(filter => {
            const i = mergedFilters.findIndex(f => f[0] === filter[0] && f[2] !== filter[2])
            if (i > -1) {
                mergedFilters[i] = filter;
            }
        })
    }

    const actualFilters = []

    const city = getClientCity(window) || DEFAULT_CITY;

    const path = {
        city: city,
        uri: normalizeRoute(url.pathname)
    }

    let prev
    if (!isUndefined(prevUrl) && prevUrl !== url.pathname) prev = prevDID

    const {promise, controller} = await getContent(path, url.search, prev)
    controllers.push(controller)

    const loaderPageConfig = await promise

    // get config to check is saved filter present in configuration
    // TODO: better to generate link
    let filterBy, filterByInline
    if (loaderPageConfig) {
        filterBy = loaderPageConfig.page.content.showFilters?.filterBy || loaderPageConfig.page.config.showFilter?.filterBy;
        filterByInline = loaderPageConfig.page.content.showFilters?.filterByInline || loaderPageConfig.page.config.showFilter?.filterByInline;    

        if (filterBy) {
            filterBy.map(filter => {
                let r = mergedFilters.find(f => f[0] === filter.id);
                if (r) actualFilters.push(r);
            })
        }

        if (filterByInline) {
            filterByInline.map(filter => {
                let r = mergedFilters.find(f => f[0] === filter.id);
                if (r) actualFilters.push(r);
            })
        }
    }

    const {urlShouldBeUpdated, activeFilters} = updateActiveFilters(actualFilters, request.url)

    if (urlShouldBeUpdated && activeFilters.length > 0) {
        const str = JSON.stringify(activeFilters.filter(f => f[0] && f[1]));
        url.searchParams.set('filters', str);

        return redirect(url.pathname + url.search);
    }
    else {
        if (url.pathname.length > 1 && url.pathname[url.pathname.length - 1] === '/') {
          return redirect(url.pathname + url.search);
        }

        return loaderPageConfig;
    }
}

export const isRenderedOnSamePage = () => {
    let result = false;

    let bot = getTelegramMode();

    if (bot) result = true;

    return result
}

export const navigateTo = (city, path, navigate) => {
    let isCityChanged = false;
    if (city === DEFAULT_CITY) {
        if (getClientCity(window) === "") isCityChanged = false;
        else isCityChanged = true;
    }
    else {
        isCityChanged = city !== getClientCity(window)
    }

    if (isCityChanged) {
        if (isRenderedOnSamePage()) {
            localStorage.setItem('city', city);
            navigate(path);
        }
        else {
            window.location.assign(getClientUrl(city, path));
        }
    }
    else {
        navigate(path);
    }
}
