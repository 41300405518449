var _a = require("lodash"), isUndefined = _a.isUndefined, isObject = _a.isObject, isString = _a.isString, isArray = _a.isArray, isNull = _a.isNull, isString = _a.isString;
var slugify = require('transliteration').slugify;
export function isFilePath(path) {
    var splited = path.split('/');
    var file = splited[splited.length - 1];
    if (file.indexOf('.') === -1)
        return false;
    return true;
}
export function isEmptyMessengerData(messenger) {
    if (!messenger)
        return true;
    if (isEmptyObj(messenger))
        return true;
    if (Object.keys(messenger).length > 1) {
        console.error('Field has more than one messenger');
        return true;
    }
    var result = false;
    Object.keys(messenger).map(function (i) {
        if (isEmptyObj(messenger[i]))
            result = true;
        else if (!messenger[i].phone && !messenger[i].username)
            result = true;
        else if (messenger[i].phone && isEmptyObj(messenger[i].phone))
            result = true;
        else if (messenger[i].phone && (!messenger[i].phone.code || !messenger[i].phone.number))
            result = true;
    });
    return result;
}
export function isNullFile(file) {
    if (!file)
        return true;
    return file.name === "null-file" && file.size == 0;
}
export function isFile(file) {
    if (!isObject(file))
        return false;
    if (!(file && typeof file.size === 'number'))
        return false;
    if (!(file && typeof file.type === 'string'))
        return false;
    if (!(file && typeof file.name === 'string'))
        return false;
    return true;
}
export function isEmptyObj(obj) {
    if (isUndefined(obj))
        return true;
    if (!isObject(obj))
        return true;
    return Object.keys(obj).length === 0;
}
export function removeEmptyFieldsFromObj(obj) {
    var result = {};
    Object.keys(obj).map(function (key) {
        if (typeof obj[key] !== 'undefined') {
            result[key] = obj[key];
        }
    });
    return result;
}
export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}
export function isDateString(date) {
    var regex = /^\d{4}(-)\d{2}(-)\d{2}/;
    return regex.test(date);
}
export var removeEmptyValuesFromObj = function (form) {
    var result = {};
    Object.keys(form).map(function (id) {
        if (isNull(form[id])) {
            result[id] = null;
        }
        else if (form[id] instanceof FileList) {
            result[id] = form[id];
        }
        else if (form[id] instanceof Date) {
            result[id] = form[id];
        }
        else if (isFile(form[id])) {
            result[id] = form[id];
        }
        else if (typeof form[id] === 'string') {
            if (form[id].length > 0)
                result[id] = form[id];
        }
        else if (isArray(form[id])) {
            result[id] = form[id];
        }
        else if (typeof form[id] === 'object') {
            if (Object.keys(form[id]).length > 0)
                result[id] = form[id];
        }
        else if (typeof form[id] !== 'undefined') {
            result[id] = form[id];
        }
    });
    return result;
};
export function sortArrayBy(key) {
    return function (a, b) { var _a; return (_a = a[key]) === null || _a === void 0 ? void 0 : _a.localeCompare(b[key]); };
}
export function transliterate(string) {
    return slugify(string
        .replace(/ї/gi, 'yi')
        .replace(/ґ/gi, 'g')
        .replace(/я/gi, 'ya')
        .replace(/ий/gi, 'yi')
        .replace(/г/gi, 'h')
        .replace(/и/gi, 'y')
        .replace(/є/gi, 'ye')
        .replace(/ /gi, '-')
        .replace(/\//gi, '')
        .replace(/\\/gi, '')
        .replace(/[^0-9a-zA-Zа-яА-ЯіїґєІЇҐЄ/-]/g, "")
        .replace(/(-+)(?=\1)/gi, ""), {
        lower: true,
        locale: 'uk',
    });
}
export function isJSON(str) {
    try {
        JSON.parse(str);
    }
    catch (e) {
        return false;
    }
    return true;
}
export function CurrencyFormat(value, currency, minimumFractionDigits) {
    if (currency === void 0) { currency = 'usd'; }
    if (minimumFractionDigits === void 0) { minimumFractionDigits = 0; }
    if (isUndefined(value))
        return '';
    var result;
    try {
        result = new Intl.NumberFormat('uk-UA', {
            style: 'currency',
            minimumFractionDigits: minimumFractionDigits,
            currency: currency,
        }).format(value);
    }
    catch (e) {
        if (isString(currency))
            result = value + " " + currency.toUpperCase();
        else
            result = value + " " + currency.toString();
    }
    return result;
}
;
