var FORMATTER = require('../const/messengers').FORMATTER;
var getTelegramContact = require('../helpers/messengers').getTelegramContact;
var CurrencyFormat = require('./utils').CurrencyFormat;
export function orderTitle(formatter, data) {
    var title;
    if (formatter === FORMATTER.EXCHANGE) {
        title = ExchangeOrderTitleText(data, false, true);
    }
    else if (formatter === FORMATTER.NETWORKING) {
        title = NetworkingOrderTitleText(data);
    }
    else {
        title = OrderTitleText(data === null || data === void 0 ? void 0 : data.order);
    }
    return title;
}
export function getExchangeMessage(partnerFormConfig, orderData, oid, cid) {
    var _a, _b, _c, _d;
    var template = {};
    if ((_b = (_a = orderData === null || orderData === void 0 ? void 0 : orderData.data) === null || _a === void 0 ? void 0 : _a.messenger) === null || _b === void 0 ? void 0 : _b.telegram) {
        template = getExchangeTelegramMessageOptions(partnerFormConfig, orderData, oid, cid);
    }
    else if ((_d = (_c = orderData === null || orderData === void 0 ? void 0 : orderData.data) === null || _c === void 0 ? void 0 : _c.messenger) === null || _d === void 0 ? void 0 : _d.whatsapp) {
        template = getExchangeWhatsAppMessageOptions(partnerFormConfig, orderData, oid, cid);
    }
    else {
        template = getExchangeTextMessageOptions(partnerFormConfig, orderData, oid, cid);
    }
    return getExchangeMessageFormat(template, partnerFormConfig, orderData, cid);
}
export function getExchangeTextMessage(partnerFormConfig, orderData, oid, cid) {
    var template = getExchangeTextMessageOptions(partnerFormConfig, orderData, oid, cid);
    return getExchangeMessageFormat(template, partnerFormConfig, orderData, cid);
}
export function getExchangeWebMessage(partnerFormConfig, orderData, oid, cid) {
    var template = getExchangeWebMessageOptions(partnerFormConfig, orderData, oid, cid);
    return getExchangeWebMessageFormat(template, partnerFormConfig, orderData, cid);
}
export function getExchangeWhatsAppMessage(partnerFormConfig, orderData, oid, cid) {
    var template = getExchangeWhatsAppMessageOptions(partnerFormConfig, orderData, oid, cid);
    return getExchangeMessageFormat(template, partnerFormConfig, orderData, cid);
}
export function getExchangeTelegramMessage(partnerFormConfig, orderData, oid, cid) {
    var template = getExchangeTelegramMessageOptions(partnerFormConfig, orderData, oid, cid);
    return getExchangeMessageFormat(template, partnerFormConfig, orderData, cid);
}
export function getExchangeMessageFormat(template, partnerFormConfig, orderData, cid) {
    if (!partnerFormConfig || !orderData)
        return '';
    var result = "".concat(template.hello, "\n\n").concat(template.header, "\n").concat(template.order, "\n\n").concat(template.check);
    return result;
}
export function getExchangeWebMessageFormat(template, partnerFormConfig, orderData, cid) {
    if (!partnerFormConfig || !orderData)
        return '';
    var result = "".concat(template.hello, "<br />\n<br />\n").concat(template.header, "<br />\n").concat(template.order, "<br />\n<br />\n").concat(template.check);
    {
    }
    return result;
}
function getExchangeWebMessageOptions(partnerFormConfig, orderData, oid, cid) {
    if (!partnerFormConfig || !orderData)
        return {};
    var hello = "\uD83D\uDCB8 \u0412\u0456\u0442\u0430\u0454\u043C\u043E! \u0412\u0438 \u0437\u0440\u043E\u0431\u0438\u043B\u0438 \u0437\u0430\u043C\u043E\u0432\u043B\u0435\u043D\u043D\u044F \u043D\u0430 \u0441\u0430\u0439\u0442\u0456 https://".concat(orderData._city, ".miy-prostir.online/");
    var did;
    if (partnerFormConfig.links[orderData._city]._did) {
        did = partnerFormConfig.links[orderData._city]._did;
    }
    else {
        did = partnerFormConfig.links[orderData._city];
    }
    var link = "https://".concat(orderData._city, ".miy-prostir.online/?goToDid=").concat(orderData._city, ":").concat(did);
    var type = orderData.form ? 'form' : orderData.shop ? 'shop' : '-';
    var check = "\u2611\uFE0F \u041F\u0435\u0440\u0435\u0432\u0456\u0440\u0438\u0442\u0438 \u0437\u0430\u044F\u0432\u043A\u0443:\n<br>\nhttps://".concat(orderData._city, ".miy-prostir.online/?checkOrder=").concat(type, ":").concat(orderData.partner, ":").concat(oid);
    var title = orderTitle(orderData.formatter, orderData === null || orderData === void 0 ? void 0 : orderData.data);
    var header = "<b>".concat(partnerFormConfig.title, ": ").concat(title, "</b>");
    var order = "<i>\u041D\u043E\u043C\u0435\u0440 \u0437\u0430\u044F\u0432\u043A\u0438: ".concat(oid, "</i>");
    var result = { hello: hello, link: link, check: check, header: header, order: order };
    return result;
}
function getExchangeTextMessageOptions(partnerFormConfig, orderData, oid, cid) {
    if (!partnerFormConfig || !orderData)
        return {};
    var hello = "\uD83D\uDCB8 \u0412\u0456\u0442\u0430\u0454\u043C\u043E! \u0412\u0438 \u0437\u0440\u043E\u0431\u0438\u043B\u0438 \u0437\u0430\u043C\u043E\u0432\u043B\u0435\u043D\u043D\u044F \u043D\u0430 \u0441\u0430\u0439\u0442\u0456 https://".concat(orderData._city, ".miy-prostir.online/");
    var did;
    if (partnerFormConfig.links[orderData._city]._did) {
        did = partnerFormConfig.links[orderData._city]._did;
    }
    else {
        did = partnerFormConfig.links[orderData._city];
    }
    var link = "https://".concat(orderData._city, ".miy-prostir.online/?goToDid=").concat(orderData._city, ":").concat(did);
    var type = orderData.form ? 'form' : orderData.shop ? 'shop' : '-';
    var check = "\u2611\uFE0F \u041F\u0435\u0440\u0435\u0432\u0456\u0440\u0438\u0442\u0438 \u0437\u0430\u044F\u0432\u043A\u0443:\nhttps://".concat(orderData._city, ".miy-prostir.online/?checkOrder=").concat(type, ":").concat(orderData.partner, ":").concat(oid);
    var title = orderTitle(orderData.formatter, orderData === null || orderData === void 0 ? void 0 : orderData.data);
    var header = "".concat(partnerFormConfig.title, ": ").concat(title);
    var order = "\u041D\u043E\u043C\u0435\u0440 \u0437\u0430\u044F\u0432\u043A\u0438: ".concat(oid);
    var result = { hello: hello, link: link, check: check, header: header, order: order };
    return result;
}
function getExchangeWhatsAppMessageOptions(partnerFormConfig, orderData, oid, cid) {
    if (!partnerFormConfig || !orderData)
        return {};
    var hello = "\u0412\u0456\u0442\u0430\u0454\u043C\u043E! \u0412\u0438 \u0437\u0440\u043E\u0431\u0438\u043B\u0438 \u0437\u0430\u043C\u043E\u0432\u043B\u0435\u043D\u043D\u044F \u043D\u0430 \u0441\u0430\u0439\u0442\u0456 https://".concat(orderData._city, ".miy-prostir.online/");
    var did;
    if (partnerFormConfig.links[orderData._city]._did) {
        did = partnerFormConfig.links[orderData._city]._did;
    }
    else {
        did = partnerFormConfig.links[orderData._city];
    }
    var link = "https://".concat(orderData._city, ".miy-prostir.online/?goToDid=").concat(orderData._city, ":").concat(did);
    var type = orderData.form ? 'form' : orderData.shop ? 'shop' : '-';
    var check = "\u041F\u0435\u0440\u0435\u0432\u0456\u0440\u0438\u0442\u0438 \u0437\u0430\u044F\u0432\u043A\u0443:\nhttps://".concat(orderData._city, ".miy-prostir.online/?checkOrder=").concat(type, ":").concat(orderData.partner, ":").concat(oid);
    var title = orderTitle(orderData.formatter, orderData === null || orderData === void 0 ? void 0 : orderData.data);
    var header = "*".concat(partnerFormConfig.title, ": ").concat(title, "*");
    var order = "_\u041D\u043E\u043C\u0435\u0440 \u0437\u0430\u044F\u0432\u043A\u0438: ".concat(oid, "_");
    var result = { hello: hello, link: link, check: check, header: header, order: order };
    return result;
}
function getExchangeTelegramMessageOptions(partnerFormConfig, orderData, oid, cid) {
    if (!partnerFormConfig || !orderData)
        return {};
    var hello = "\uD83D\uDCB8 \u0412\u0456\u0442\u0430\u0454\u043C\u043E! \u0412\u0438 \u0437\u0440\u043E\u0431\u0438\u043B\u0438 \u0437\u0430\u043C\u043E\u0432\u043B\u0435\u043D\u043D\u044F \u0432 \u0431\u043E\u0442\u0456 @MiyProstirBot";
    var did;
    if (partnerFormConfig.links[orderData._city]._did) {
        did = partnerFormConfig.links[orderData._city]._did;
    }
    else {
        did = partnerFormConfig.links[orderData._city];
    }
    var link = "https://t.me/MiyProstirBot/?startapp=goToDid---".concat(orderData._city, "___").concat(did);
    var type = orderData.form ? 'form' : orderData.shop ? 'shop' : '-';
    var check = "\u2611\uFE0F \u041F\u0435\u0440\u0435\u0432\u0456\u0440\u0438\u0442\u0438 \u0437\u0430\u044F\u0432\u043A\u0443:\nhttps://t.me/MiyProstirBot/?startapp=checkOrder---".concat(type, "___").concat(orderData.partner, "___").concat(oid);
    var title = orderTitle(orderData.formatter, orderData === null || orderData === void 0 ? void 0 : orderData.data);
    var header = "**".concat(partnerFormConfig.title, ": ").concat(title, "**");
    var order = "__\u041D\u043E\u043C\u0435\u0440 \u0437\u0430\u044F\u0432\u043A\u0438: ".concat(oid, "__");
    var result = { hello: hello, link: link, check: check, header: header, order: order };
    return result;
}
export function getExchangeUrl(data, message) {
    var _a, _b;
    if ((_a = data === null || data === void 0 ? void 0 : data.messenger) === null || _a === void 0 ? void 0 : _a.telegram) {
        return getExchangeTelegramUrl(data, message);
    }
    else if ((_b = data === null || data === void 0 ? void 0 : data.messenger) === null || _b === void 0 ? void 0 : _b.whatsapp) {
        return getExchangeWhatsAppUrl(data, message);
    }
    return false;
}
export function getExchangeTelegramUrl(data, message) {
    var _a;
    var tid = getTelegramContact((_a = data === null || data === void 0 ? void 0 : data.messenger) === null || _a === void 0 ? void 0 : _a.telegram);
    if (tid) {
        return "https://t.me/".concat(tid, "?text=").concat(message);
    }
    return false;
}
export function getExchangeWhatsAppUrl(data, message) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var wid;
    if ((_b = (_a = data === null || data === void 0 ? void 0 : data.messenger) === null || _a === void 0 ? void 0 : _a.whatsapp) === null || _b === void 0 ? void 0 : _b.phone) {
        wid = ((_e = (_d = (_c = data === null || data === void 0 ? void 0 : data.messenger) === null || _c === void 0 ? void 0 : _c.whatsapp) === null || _d === void 0 ? void 0 : _d.phone) === null || _e === void 0 ? void 0 : _e.code) + ((_h = (_g = (_f = data === null || data === void 0 ? void 0 : data.messenger) === null || _f === void 0 ? void 0 : _f.whatsapp) === null || _g === void 0 ? void 0 : _g.phone) === null || _h === void 0 ? void 0 : _h.number);
    }
    if (wid) {
        return "https://wa.me/".concat(wid, "?text=").concat(message);
    }
    return false;
}
export function getExchangeBotMessage(partnerFormConfig, orderData, oid, userHasBot) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    if (!partnerFormConfig || !orderData)
        return '';
    var title = orderTitle(orderData.formatter, orderData === null || orderData === void 0 ? void 0 : orderData.data);
    var contact;
    if ((_b = (_a = orderData === null || orderData === void 0 ? void 0 : orderData.data) === null || _a === void 0 ? void 0 : _a.messenger) === null || _b === void 0 ? void 0 : _b.telegram) {
        contact = "\u0422\u0435\u043B\u0435\u0433\u0440\u0430\u043C \u043A\u043E\u043D\u0442\u0430\u043A\u0442\u0430\u043C\u0438: ".concat(getTelegramContact((_d = (_c = orderData === null || orderData === void 0 ? void 0 : orderData.data) === null || _c === void 0 ? void 0 : _c.messenger) === null || _d === void 0 ? void 0 : _d.telegram));
    }
    else if ((_f = (_e = orderData === null || orderData === void 0 ? void 0 : orderData.data) === null || _e === void 0 ? void 0 : _e.messenger) === null || _f === void 0 ? void 0 : _f.whatsapp) {
        contact = "WhatsApp \u043A\u043E\u043D\u0442\u0430\u043A\u0442\u0430\u043C\u0438: ".concat((_k = (_j = (_h = (_g = orderData === null || orderData === void 0 ? void 0 : orderData.data) === null || _g === void 0 ? void 0 : _g.messenger) === null || _h === void 0 ? void 0 : _h.whatsapp) === null || _j === void 0 ? void 0 : _j.phone) === null || _k === void 0 ? void 0 : _k.code).concat((_p = (_o = (_m = (_l = orderData === null || orderData === void 0 ? void 0 : orderData.data) === null || _l === void 0 ? void 0 : _l.messenger) === null || _m === void 0 ? void 0 : _m.whatsapp) === null || _o === void 0 ? void 0 : _o.phone) === null || _p === void 0 ? void 0 : _p.number);
    }
    else {
        contact = 'контактами ';
    }
    var type = orderData.form ? 'form' : orderData.shop ? 'shop' : '-';
    var check;
    if (userHasBot)
        check = "https://t.me/MiyProstirBot/?startapp=checkOrder---".concat(type, "___").concat(orderData.partner, "___").concat(oid);
    else
        check = "https://".concat(orderData._city, ".miy-prostir.online/?checkOrder=").concat(type, ":").concat(orderData.partner, ":").concat(oid);
    var result = "\uD83D\uDCB8 \u0412\u0456\u0442\u0430\u0454\u043C\u043E! \u0412\u0438 \u0437\u0440\u043E\u0431\u0438\u043B\u0438 \u0437\u0430\u043C\u043E\u0432\u043B\u0435\u043D\u043D\u044F \u0432 @MiyProstirBot<br>\n<br>\n<b>".concat(partnerFormConfig.title, ": ").concat(title, "</b>\n<i>\u041D\u043E\u043C\u0435\u0440 \u0437\u0430\u044F\u0432\u043A\u0438: ").concat(oid, "</i>\n<br>\n<br>\n");
    result += "\u26A0\uFE0F \u041E\u043F\u0435\u0440\u0430\u0442\u043E\u0440 \u0441\u0435\u0440\u0432\u0456\u0441\u0443 \u043D\u0435 \u043C\u043E\u0436\u0435 \u0437\u0432\u02BC\u044F\u0437\u0430\u0442\u0438\u0441\u044C \u0437 \u0432\u0430\u043C\u0438 \u0437\u0430 \u0432\u043A\u0430\u0437\u0430\u043D\u0438\u043C\u0438 ".concat(contact, "<br>\n<br>\n\uD83D\uDCAC \u041D\u0430\u043F\u0438\u0448\u0456\u0442\u044C \u043E\u043F\u0435\u0440\u0430\u0442\u043E\u0440\u0443 \u0441\u0435\u0440\u0432\u0456\u0441\u0443 @").concat(partnerFormConfig.manager.tid, ", \u0434\u043B\u044F \u0443\u0442\u043E\u0447\u043D\u0435\u043D\u043D\u044F \u0434\u0435\u0442\u0430\u043B\u0435\u0439 \u0437\u0430\u043C\u043E\u0432\u043B\u0435\u043D\u043D\u044F<br>\n<br>\n\u2611\uFE0F <a href=\"").concat(check, "\">\u041F\u0435\u0440\u0435\u0432\u0456\u0440\u0438\u0442\u0438 \u0437\u0430\u044F\u0432\u043A\u0443</a>");
    return result;
}
export function ExchangeOrderTitleText(data, lowCase, short, pre) {
    var amount = data.amount;
    var currency = data.currency;
    var cryptoCurrency = data['crypto-currency'];
    if (!amount || (!currency && !cryptoCurrency))
        return 'Відсутні параметри заявки';
    var outcome = data['outcome-currency'];
    var o = ((outcome === null || outcome === void 0 ? void 0 : outcome.value) && (outcome === null || outcome === void 0 ? void 0 : outcome.value) === "_else") ? outcome.else : outcome;
    var msg;
    if ((currency === null || currency === void 0 ? void 0 : currency.value) && (currency === null || currency === void 0 ? void 0 : currency.value) === "_else") {
        var shortTitle = "".concat(amount, " ").concat(currency === null || currency === void 0 ? void 0 : currency.else);
        if (short) {
            msg = shortTitle;
        }
        else {
            if (o)
                msg = "".concat(amount, " ").concat(currency === null || currency === void 0 ? void 0 : currency.else, " => ").concat(CurrencyFormat(0, o).substring(2));
            else
                msg = shortTitle;
        }
    }
    else if (currency) {
        var c = (currency === null || currency === void 0 ? void 0 : currency.value) || currency;
        var shortTitle = CurrencyFormat(amount, c);
        if (short) {
            msg = shortTitle;
        }
        else {
            if (o)
                msg = "".concat(CurrencyFormat(amount, c), " => ").concat(CurrencyFormat(0, o).substring(2));
            else
                msg = shortTitle;
        }
    }
    else if (cryptoCurrency) {
        var shortTitle = "".concat(amount, " ").concat(cryptoCurrency);
        if (short) {
            msg = shortTitle;
        }
        else {
            if (o)
                msg = "".concat(CurrencyFormat(amount, c), " => ").concat(CurrencyFormat(0, o).substring(2));
            else
                msg = shortTitle;
        }
    }
    if (msg && lowCase) {
        msg = msg[0].toLowerCase() + msg.substring(1);
    }
    if (pre && msg)
        msg = pre + " " + msg;
    return msg;
}
export function NetworkingOrderTitleText(order, attachText, pre) {
    var title = (order === null || order === void 0 ? void 0 : order.title) || '';
    return title;
}
export function OrderTitleText(order) {
    var title = order === null || order === void 0 ? void 0 : order.map(function (item) { var _a; return (_a = item === null || item === void 0 ? void 0 : item.details) === null || _a === void 0 ? void 0 : _a.title; }).join(', ');
    return title;
}
