import Chip from '@mui/material/Chip';
import {useNavigate} from 'react-router-dom';

import FilterValue from '../FilterValue';

import {updateActiveFilters, getActiveFilterFromURL} from '../../Helpers/filters';

import './index.scss';

export default function FilterChip(props) {
    const {value, config, className, clickable, deleteIcon, onDelete} = props;

    const navigate = useNavigate();

    const clickableHandler = () => {
        let url = new URL(window.location.href);

        const activeFilter = getActiveFilterFromURL()
        let filters = activeFilter.filters || []

        const newValue = config?.options?.multi ? [value] : value;

        if (url.search) {
            let filterUpdated = false;
            filters.map((f, i) => {
                if (f[0] === config.id) {
                    filters[i][2] = newValue
                    filterUpdated = true;
                }
            })

            if (!filterUpdated) {
                filters.push([
                    config.id,
                    '==',
                    newValue
                ])
            }
        }
        else {
            if (!filters) filters = []

            filters.push([
                config.id,
                '==',
                newValue
            ])
        }

        const {activeFilters} = updateActiveFilters(filters)
        const str = JSON.stringify(activeFilters.filter(f => f[0] && f[1]));

        url.searchParams.set('filters', str);
        url.searchParams.delete('page');
        url.searchParams.delete('perPage');
        sessionStorage.setItem("filters", str);

        navigate(url.pathname + url.search);
    }

    if (!value) return <></>

    if (clickable)
        return <span className='pseudoLink' onClick={clickableHandler}>#<FilterValue value={value} config={config} /></span>
    
    return <Chip className={className} label={<FilterValue value={value} config={config} />} onDelete={onDelete} deleteIcon={deleteIcon} variant="outlined" />
}

