import {useEffect, useState} from 'react'

import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// import Grid from '@mui/material/Grid';
// import AttachFileIcon from '@mui/icons-material/AttachFile';

// import {getStoreFileName} from 'ultra/helpers/storage';
import {isFile} from 'ultra/helpers/utils';
import {revertPath} from 'ultra/helpers/route';
import {transliterate} from 'ultra/helpers/utils';

import {useToasterStore} from '../../../Stores/toster';
// import {useBackdropPreloaderStore} from '../../../Stores/backdropPreloader';

import UploadImage from '../UploadImage';

import {getInstaLogo} from '../../../Helpers/utils';

import ContentImage from '../../../Modules/Content/Widgets/ContentImage';
import ButtonWithPreloader from '../ButtonWithPreloader'

// import AdaptiveDialog from '../../Adaptive/Dialog';

import './index.scss';

export default function LogoUploader(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;

    const {showError} = useToasterStore(); // showSuccess

    // const inputRef = useRef(null);
    // const hiddenImagesRef = useRef(null);

    // const {hideBackdropPreloader} = useBackdropPreloaderStore();
    // const {toaster, showPromt, closeToaster} = useToasterStore();

    // const [name, setName] = useState('')
    // const [img, setImg] = useState(null)
    // const [imgAvailable, setImgAvailable] = useState(false)

    // const [filesUploader, setFilesUploader] = useState();
    // const [selectedUploader, setSelectedUploader] = useState();
    // const [content[field.id], setInstagramLogoPath] = useState();

    const [fileContent, setFileContent] = useState({});
    const [instagramAccount, setInstagramAccount] = useState('');
    const [instagramAccountInProgress, setInstagramAccountInProgress] = useState();
    const [fileType, setFileType] = useState();
    const [img, setImg] = useState(null)

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    const [placeholder] = useState(field.placeholder ? field.placeholder + (field.required ? ' *' : '') : undefined)

    // const [sizeInfo, setSizeInfo] = useState(null);
    // const [textFieldClassName, setTextFieldClassName] = useState()
    // const [actionClassName, setActionClassName] = useState(showErrorText ? 'action error' : 'action')

    // useEffect(() => {
    //     if (field?.options?.size) {
    //         setSizeInfo(`Рекомендовані розміри зображення - ${field?.options?.size.width} x ${field?.options?.size.height}`)
    //     }

    //     setTextFieldClassName(getTextFieldClassName());
    // }, [])

    useEffect(() => {
        const value = content[field.id];

        if (isFile(value)) {
            // file
            setImg(URL.createObjectURL(value))
        } else if (value && typeof value === 'string') {
            // string
            setImg(value)
        } else {
            setImg(null)
        }

        setFileContent({[field.id]: value})

    }, [content[field.id]])

    // useEffect(() => {
    //     setTextFieldClassName(getTextFieldClassName());
    //     setActionClassName(showErrorText ? 'action error' : 'action')
    // }, [showErrorText])

    // const getTextFieldClassName = () => {
    //     let textFieldClassName = isDisabled ? 'filesList' : 'filesList emulateEnabled';
    //     textFieldClassName += showErrorText ? ' error' : ''
    //     return textFieldClassName
    // }

    const cleanValue = () => {
        onChange(null);

        // TODO
        // unblur field

        // TODO: ON CANCEL
        // getInstaLogo(instagramAccount)
        //     .controller
    }

    const onInstagramFieldChange = (e) => {
        setInstagramAccount(e.target.value);

        // TODO: enable button only after 3 symbols in name
    }

    const getInstagramLogo = () => {
        setInstagramAccountInProgress(true)

        let acc = instagramAccount;
        if (acc.includes('instagram.com/')) {
            const parts = acc.split('instagram.com/');
            if (parts[1]) {
                acc = parts[1]?.split('/')[0];
                acc = acc?.split('?')[0];
            }
        }

        if (!acc) {
            showError({snackbarMessage: 'Потрібно вказати instagram аккаунт'})
            return;
        }

        // getInstaLogo(acc, content._did, field.id, content._type, content._parent + '/' + (content.title || content[field.id]))
        getInstaLogo(acc, content._did, field.id, content._type, revertPath(content._parent + '/' + (transliterate(content.title) || content[field.id])))
            // .promise
            .then(result => {
                setInstagramAccountInProgress(false)

                if (result?.[field.id]) {
                    onChange({target: {value: result[field.id]}})
                }
            })
            .catch(e => {
                setInstagramAccountInProgress(false)
                showError({snackbarMessage: e.message})
            })
    }

    const handleTypeChange = (event) => {
        cleanValue();
        setInstagramAccount('');
        setFileType(event.target.value);
    };

    const onFileChange = (e) => {
        setFileContent({[field.id]: img})
        onChange(e);
    }

    const onKeyDownHandler = (e) => {
        const regex = new RegExp('^[A-Za-z\-\—\⸺\_\/\: ]*$');
        const isBackspace = e.keyCode === 8;
        const isArrow = e.keyCode >= 37 && e.keyCode <=40 ;

        if (!isArrow && !isBackspace && !regex.test(e.key)) {
            e.preventDefault();
            // setRegexpHighlight(true);
        }
        else {
            // setRegexpHighlight(false);
        }
    }

    const uploadFieldConfig = {
        id: field.id,
    }
    if (field?.options) {
        uploadFieldConfig.options = {}
        uploadFieldConfig.options.size = field.options.size
    }

    return <div className={'LogoUploader' + (isDisabled ? ' disabled' : '')}>

        <div className='pseudoButton'>
            <div className='select'>
                <FormLabel>{placeholder}:</FormLabel>
                <RadioGroup row onChange={handleTypeChange}>
                    <FormControlLabel value="instagram" control={<Radio />} label="З Інстаграму" disabled={Boolean(isDisabled || instagramAccountInProgress || content[field.id])}/>
                    <FormControlLabel value="file" control={<Radio />} label="Файл" disabled={Boolean(isDisabled || instagramAccountInProgress || content[field.id])}/>
                </RadioGroup>
            </div>

            <Collapse in={fileType === 'instagram'}>
                <div className='inputs'>
                    <div className='wrapper'>
                        <TextField
                            fullWidth
                            value={instagramAccount}
                            label="Instagram аккаунт"
                            variant="outlined"
                            onChange={onInstagramFieldChange}
                            onKeyDown={onKeyDownHandler}
                            disabled={Boolean(instagramAccountInProgress || content[field.id])} // isDisabled || 
                            // required={field?.required}
                            // error={Boolean(showErrorText)}
                            // helperText={showErrorText}
                            // onKeyDown={onKeyDownHandler}
                            // inputProps={{maxLength: field?.options?.maxLength}}
                        />

                        <ButtonWithPreloader
                            variant="contained"
                            component="label"
                            tabIndex={-1}
                            fullWidth
                            disabled={Boolean(!instagramAccount || instagramAccount.length === 0 || content[field.id])}
                            onClick={getInstagramLogo}
                            inprogress={instagramAccountInProgress?.toString() || 'false'}
                        >
                            <DownloadIcon />
                        </ButtonWithPreloader>
                    </div>

                    {!img && <div className="fieldNotes">
                        {!instagramAccountInProgress && <p>* Приклади: <b>bali_ukraine</b> або <b>https://instagram.com/bali_ukraine/</b></p>}
                        {!instagramAccountInProgress && <p>Можна ввести дані лише англійською мовою</p>}
                        {instagramAccountInProgress && <p className='timer'><b>УВАГА!:</b> Завантаження може зайняти до 30 секунд</p>}
                    </div>}
                </div>
            </Collapse>

            {fileType === 'file' && <div className='inputs'>
                <UploadImage
                    field={uploadFieldConfig}
                    content={fileContent}
                    onChange={onFileChange}
                    hidePreview
                />
            </div>}

            {img && <div className='photoWrap'>
                <div className='photoWrapInner'>
                    {!isFile(img) && <ContentImage src="thumbnail" image={img} className="photo" />}
                    {isFile(img) && <img loading="lazy" src={img} className="photo" />}

                    <span className='close' onClick={cleanValue}>
                        <ClearIcon />
                    </span>
                </div>
            </div>}
        </div>
    </div>
}
