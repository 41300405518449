import {FORMATTER} from "ultra/const/messengers";

import FormValues from '../../../../../../../Components/FormValues';

import './index.scss';

export default function Template(props) {
    const {code, values, short} = props;

    if (!values) return <></>;

    if (short) {
        return <div className="NotificationTitle">
            <div>Нове замовлення</div>
            {/* <div className='NotificationSubitle'>#{values.details._oid}</div> */}
        </div>
    }

    return <div className='NotificationFormSubmit'>
        <div className="NotificationTitle">
            {values?.config?.title}
        </div>

        {values.config.orderFormatter === FORMATTER.EXCHANGE && <FormValues config={values?.config?.links[values.details._city]} data={values.details.data} ignore={['messenger']} />}
        {values.config.orderFormatter !== FORMATTER.EXCHANGE && <FormValues config={values?.config?.links[values.details._city]} data={values.details.data} />}
    </div>
}
