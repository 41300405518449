import {useNavigationStore} from '../../../../../../../../Stores/navigation';

import RecomendationDescriptionPreloader from '../../../../../../../../Components/RecomendationDescriptionPreloader'
import PreloaderText from '../../../../../../../../Components/PreloaderText';

import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import ContentImage from '../../../../../../Widgets/ContentImage'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'

import './index.scss';

export default function Template(props) {
    const {content, permits, config, breadcrumbs} = props

    const {contentLoading} = useNavigationStore()

    return <div className='Chat'>
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {contentLoading && <PreloaderText />}

        {!contentLoading && <>
            <ContentImage src="thumbnail" image={content.thumbnail} className="ChatLogo" />

            <CKEditorContent content={content.description} />

            {content.link && <p className='labelWrap labelWrapRow'>
                <span className="label">Посилання:</span>
                <span className="labelValue">
                    <a
                        href={content.link.link}
                        target="_blank"
                    >
                        {content.link.title || content.link.link}
                    </a>
                </span>
            </p>}
        </>}
    </div>
}
