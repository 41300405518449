var isUndefined = require("lodash").isUndefined;
import { isEmptyObj } from "../helpers/utils";
export function getMessengerType(messenger) {
    if (!isUndefined(messenger === null || messenger === void 0 ? void 0 : messenger.telegram))
        return 'telegram';
    if (!isUndefined(messenger === null || messenger === void 0 ? void 0 : messenger.whatsapp))
        return 'whatsapp';
    return '';
}
export var getTelegramContact = function (original, addAtSign) {
    if (addAtSign === void 0) { addAtSign = true; }
    if (original.phone) {
        if (!isEmptyObj(original.phone))
            return original.phone.code + original.phone.number;
        return '';
    }
    else if (original.username) {
        return addAtSign ? '@' + original.username : original.username;
    }
    else {
        return '';
    }
};
export var getWhatAppContact = function (original) {
    if (!isEmptyObj(original.phone))
        return original.phone.code + original.phone.number;
    return '';
};
var getLink = function (value, domain, prefix) {
    if (prefix === void 0) { prefix = ''; }
    if (!value)
        return {};
    var website = 'https://' + domain + '/';
    if (value.includes(domain)) {
        var end = value.indexOf('?') > 0 ? value.indexOf('?') : value.length;
        return {
            href: value.substring(0, end),
            title: value.substring(value.indexOf(domain + '/') + (domain + '/').length, end),
        };
    }
    if (value[value.length - 1] === '/') {
        value = value.substring(0, value.length - 1);
    }
    if (value.indexOf(website) === 0) {
        return {
            href: value,
            title: "".concat(prefix).concat(value.substring(website.length))
        };
    }
    if (value.indexOf(website) === -1) {
        return {
            href: "".concat(website).concat(value),
            title: "".concat(prefix).concat(value)
        };
    }
    return {
        href: value,
        title: value,
    };
};
export function getMessengerAccount(messenger) {
    if (!messenger)
        return "";
    var type = getMessengerType(messenger);
    var acccount;
    if (messenger[type].username) {
        acccount = '@';
        acccount += messenger[type].username;
    }
    else if (messenger[type].phone) {
        acccount = messenger[type].phone.code + messenger[type].phone.number;
    }
    return acccount;
}
export function getMessengerLink(messenger) {
    var _a, _b;
    var link;
    switch (getMessengerType(messenger)) {
        case 'telegram':
            link = (_a = getTelegramLink(getTelegramContact(messenger.telegram, false))) === null || _a === void 0 ? void 0 : _a.href;
            break;
        case 'whatsapp':
            link = (_b = getWhatAppLink(getWhatAppContact(messenger.whatsapp))) === null || _b === void 0 ? void 0 : _b.href;
            break;
    }
    return link;
}
export var getWhatAppLink = function (value) {
    return getLink(value, 'wa.me', '');
};
export var getTelegramLink = function (value) {
    return getLink(value, 't.me', '');
};
export var getFacebookLink = function (value) {
    return getLink(value, 'facebook.com');
};
export var getInstagramLink = function (value) {
    return getLink(value, 'instagram.com');
};
