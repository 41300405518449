import {isArray, isString} from 'lodash';
import {isMobile} from "react-device-detect";

import React, {useState, useEffect} from 'react';
import Skeleton from '@mui/material/Skeleton';
import {getClientCity} from 'ultra/configs/general';

import FilterField from '../../../../Components/Form/Filter';
import Checkbox from '../../../../Components/Form/Checkbox';

import CityFilter from '../../../Content/Widgets/CityFilter';

import {getTagsFilter} from '../../../../Helpers/content';


export default function FilterSelector(props) {
    const {filter, activeFilters, size, handleChange} = props;

    // get from endpoint, and put into filter
    const [tagsValues, setTagsValues] = useState()
    const [activeFilterValue, setActiveFilterValue] = useState('')
    const [label] = useState(filter?.field?.placeholder)

    useEffect(() => {
        const active = activeFilters?.find(item => item[0] === filter.id)
        let activeFilterValue
        if (active) {
            activeFilterValue = active[2]
        }

        setActiveFilterValue(activeFilterValue)
    }, [activeFilters])

    useEffect(() => {
        const city = getClientCity(window)

        if (filter?.field?.type === 'tags') {
            getTagsFilter(city, filter.field.node, filter.field.field)
                .then(setTagsValues)
        }
    }, [filter])

    const onChange = (condition, value) => {
        const filters = activeFilters ? [...activeFilters] : [];

        const index = filters.findIndex(i => i[0] === filter.id);

        if (index > -1) {
            filters[index] = [
                filter.id,
                condition,
                value
            ];
        }
        else {
            filters.push([
                filter.id,
                condition,
                value
            ]);
        }
        // update value
        handleChange(filters);
    }

    if (filter?.field?.type === 'city') {
        return <CityFilter list={filter.field.list} />
    }

    if (filter?.field?.type === 'filter') {
        return <FilterField
                    size={size}
                    content={{
                        [filter.id]: activeFilterValue ? activeFilterValue : filter.field.default
                    }}
                    field={{
                        ...filter.field,
                        id: filter.id,
                        placeholder: label,
                    }}
                    onChange={(e) => {
                        if (
                            (!e.target.value)
                            || (e.target.value && isArray(e.target.value) && e.target.value.length === 0)
                        ) {
                            onChange(undefined, undefined)
                        } else {
                            onChange('==', isArray(e.target.value) ? JSON.stringify(e.target.value) : e.target.value)
                        }
                    }}
                />
    }

    if (filter?.field?.type === 'complex') {
        return <FilterField
            size={size}
            content={{
                [filter.id]: activeFilterValue ? activeFilterValue : filter.field.default
            }}
            field={{
                id: filter.id,
                placeholder: label,
                ...filter.field,
            }}
            onChange={(e) => {
                if (!e.target.value) {
                    onChange(undefined, undefined)
                } else {
                    onChange('filter', e.target.value)
                }
            }}
        />
    }

    if (filter?.field?.type === 'tags') {
        if (!tagsValues) {
            return <Skeleton className='FilterField' variant="rectangular" height={isMobile ? 56 : 42} />
        }

        return <FilterField
                    size={size}
                    content={{
                        [filter.id]: activeFilterValue ? activeFilterValue : filter.field.default
                    }}
                    field={{
                        id: filter.id,
                        placeholder: label,
                        options: filter?.field?.options,
                        values: {
                            values: tagsValues
                        }
                    }}
                    onChange={(e) => {
                        if (!e.target.value) {
                            onChange(undefined, undefined)
                        } else {
                            onChange('==', e.target.value)
                        }
                    }}
                />
    }

    if (filter?.field?.type === 'checkbox') {
        return <Checkbox
                    content={{
                        [filter.id]: activeFilterValue === true
                    }}
                    field={{
                        id: filter.id,
                        placeholder: label,
                        ...filter.field,
                    }}
                    onChange={(e) => {
                        if (e.target.checked) {
                            onChange('==', e.target.checked)
                        }
                        else {
                            onChange(undefined, undefined)
                        }
                    }}
                />
    }

    if (filter?.field?.type === 'all_valid_users') {
        return <Checkbox
                content={{
                    [filter.id]: activeFilterValue === false
                }}
                field={{
                    id: filter.id,
                    placeholder: label,
                    ...filter.field,
                }}
                onChange={(e) => {
                    if (e.target.checked) {
                        onChange('==', false)
                    }
                    else {
                        onChange(undefined, undefined)
                    }
                }}
            />
    }
}
