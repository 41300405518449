import { create } from 'zustand'

export const useNotifyStore = create((set) => ({
  notify: null,
  showNotify: (msg) => set(() => {
    return {notify: msg};
  }),
  cleanNotify: () => set(() => {
    return {notify: null};
  })
}))
