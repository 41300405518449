import {useRouteError, useParams} from "react-router-dom";

import {useGlobalCustomContext} from '../../../../../Helpers/context';
import {USER_ACTIONS} from '../../../Reducers/user';

import './index.scss';

function ValidateErrorPage() {
  const error = useRouteError();
  const {userDispatch} = useGlobalCustomContext();
  const {order} = useParams();

  const onLogin = () => {
    userDispatch({type: USER_ACTIONS.LOGIN})
  }

  return (
    <div id="Content" className='Content'>
      <div className='ValidateErrorPage
      '>
            {error.status === 401 && <>
                <div className="messageWrap">
                    <div className="message">
                        Для перевірки замовлення, спробуйте авторизуватись
                    </div>
                    <p className='actionsBox'><span className='pseudoButton' onClick={onLogin}>Авторизуватись</span></p>
                </div>
            </>}

            {error.status === 403 && <div className="messageWrap">
                <div className="message messageError">
                    Заявка <b>{order}</b> не належить вам
                </div>
            </div>}

            {error.status === 404 &&
                <div className="messageWrap">
                    <div className="message messageError">
                        Заявка <b>{order}</b> не існує
                    </div>
                </div>
            }
        </div>
        </div>
  );
}

export default ValidateErrorPage;
