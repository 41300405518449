import ContentImage from '../ContentImage'

import './index.scss';

export default function ImagesList(props) {
    const {city, zoom, content, className = ''} = props;

    if (!content || content.length === 0) return <></>

    return <div className={`ImagesList ${className}`}>
        {content && content.map(i => <div key={i} className='ImagesListItem'><ContentImage zoom={zoom} city={city} image={i} className="image" /></div>)}
    </div>
}

export function ImageItem(props) {
    const {city, zoom, content, className = ''} = props;

    if (!content) return <></>

    return <div className={`ImagesList ${className}`}>
        <div className='ImagesListItem'><ContentImage zoom={zoom} city={city} image={content} className="image" /></div>
    </div>
}
