import React, {useState} from 'react'; // , {useEffect}
// import {useNavigate} from "react-router-dom";
// import {getMessaging, getToken} from "firebase/messaging";

import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';

import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';

import {useNavigationStore} from '../../../../Stores/navigation';
import {useToasterStore} from '../../../../Stores/toster';

import {USER_ACTIONS} from '../../../Profile/Reducers/user';

import {signIn} from "../../Helpers/user"; // saveSubToken
import {useGlobalCustomContext} from '../../../../Helpers/context';
// import {setPageTitle} from '../../../../Helpers/router';

import SignUp from '../SignUp';
import Reset from '../Reset'

import ButtonWithPreloader from '../../../../Components/Form/ButtonWithPreloader';
import AdaptiveDialog from '../../../../Components/Adaptive/Dialog';

import './index.scss'

export default (props) => {
    const {onSuccess} = props;

    const [data, setState] = React.useState({
        email: '',
        password: '',
    });

    const {showError} = useToasterStore();

    const [openReset, setOpenReset] = useState(false);
    const [inProgress, setInProgress] = React.useState(false);

    const {email, password} = data;

    const {startLoading, stopLoading} = useNavigationStore() // contentLoading

    const {userState, userDispatch} = useGlobalCustomContext();

    const onResetClick = () => {
        // userDispatch({type: USER_ACTIONS.LOGIN_CLOSE});
        setOpenReset(true);
    }

    const handleResetClose = () => {
        setOpenReset(false);
        // userDispatch({type: USER_ACTIONS.LOGIN})
    };

    const onResetSuccess = () => {
        setOpenReset(false)
    }

    const handleRegistrationClose = () => {
        userDispatch({type: USER_ACTIONS.REGISTRAION_CLOSE});
    };
    
    const onRegisterClick = () => {
        // userDispatch({type: USER_ACTIONS.LOGIN_CLOSE});
        userDispatch({type: USER_ACTIONS.REGISTRAION})
    }

    // useEffect(() => {
    //     setPageTitle('Авторизація')
    // }, [])

    const signInWithEmail = () => {
        if (!email || !password) {
            showError({snackbarMessage: 'Вкажіть ваш email та пароль'});
            return;
        }

        if (inProgress) {
            return;
        }

        startLoading();
        setInProgress(true);

        userDispatch({type: USER_ACTIONS.USER_LOGIN_IN_PROGRESS})
        signIn(email, password)
            .then(user => {
                setState({email: '', password: ''});

                stopLoading();
                onSuccess();

                setInProgress(false);
            })
            .catch((errToasterConfig) => {
                stopLoading();
                userDispatch({type: USER_ACTIONS.USER_REMOVE})

                showError({
                    ...errToasterConfig,
                    // onCloseHandler: () => {
                    //     userDispatch({type: USER_ACTIONS.LOGIN_CLOSE});
                    // }
                })

                setInProgress(false);
            })
    }

    return (
        <div className="profileForm SignIn">
            <Grid container spacing={2} justifyContent="center" className="form">
                <Grid item xs={12} md={6}>
                    <TextField disabled={inProgress} fullWidth variant="outlined" label="Введіть ваш Email" value={email} onChange={(e) => setState({ ...data, email: e.target.value})} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField disabled={inProgress} fullWidth variant="outlined" label="Введіть ваш пароль" value={password} type="password" onChange={(e) => setState({ ...data, password: e.target.value})} />
                </Grid>

                <Grid item xs={12} md={12}>
                    <ButtonWithPreloader
                        fullWidth
                        variant="contained"
                        onClick={signInWithEmail}
                        inprogress={inProgress.toString()}
                    >
                        Увійти
                    </ButtonWithPreloader>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Button
                        fullWidth
                        startIcon={<PasswordOutlinedIcon />}
                        className='action login'
                        variant="outlined"
                        onClick={onResetClick}>
                            Відновити пароль
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button
                        fullWidth
                        startIcon={<PersonAddAltOutlinedIcon />}
                        className='action registration'
                        variant="outlined"
                        onClick={onRegisterClick}>
                            Реєстрація
                    </Button>
                </Grid>

                {/* <Grid item xs={12}>
                    🇦🇺 🇮🇩 🇩🇪 🇨🇦 🇬🇧
                </Grid> */}
            </Grid>

            <AdaptiveDialog open={openReset} onClose={handleResetClose} title="Відновити пароль">
                <Reset
                    embedded
                    onClose={handleResetClose}
                    onSuccess={onResetSuccess}
                />
            </AdaptiveDialog>

            <AdaptiveDialog open={Boolean(userState?.registrationPopupOpen)} onClose={handleRegistrationClose} title="Мій Простір">
                <SignUp
                    onClose={handleRegistrationClose}
                />
            </AdaptiveDialog>
        </div>
    )
}
