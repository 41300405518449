import {getClientCity, getClientConfig} from 'ultra/configs/general';
import {DEFAULT_CITY} from 'ultra/const/general';

import {get} from './http';

const {api} = getClientConfig(window)

export async function getInstaLogo(account, did, field, type, path) {
    const city = getClientCity(window) || DEFAULT_CITY;
    return get(`${api}/utils/instalogo/${account}/${field}/${city}/${did}/${type}/path${path}`).promise;
}
