import dayjs from 'dayjs';
import {isArray} from 'lodash';

import React from 'react';

import {NavLink as RouterLink} from "react-router-dom";
import JsonView from 'react18-json-view'
import {restorePath} from 'ultra/helpers/route'

import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import {getClientCity} from 'ultra/configs/general';
import {getFormatedDate, timestampToDate} from 'ultra/helpers/date'
import {DEFAULT_CITY, NO_CITY} from 'ultra/const/general';
import {CurrencyFormat} from 'ultra/helpers/utils';
import {totalPrice} from "ultra/helpers/shop";
import {getFacebookLink, getInstagramLink} from 'ultra/helpers/messengers';
import {getClientCityTimezone} from 'ultra/configs/general';
import {orderTitle} from 'ultra/helpers/partner';

import {useConfigStore} from '../../../../../Stores/config';

import {getBookStatusLabel} from '../../../Helpers/library';
import {isYoursAccount} from '../../../../../Helpers/user';
import {makeUrlsClickable} from '../../../../../Helpers/format';
import {useGlobalCustomContext} from '../../../../../Helpers/context';
import {getConversationTitle} from '../../../../../Helpers/conversation';

import MessageToReader from '../../../Widgets/MessageToReader';
import EmailButton from '../../../Widgets/EmailButton';
import BotNotificationButton from '../../../Widgets/BotNotificationButton';

import FiltertValue from '../../../Widgets/FilterValue';
import FilterMultiChip from '../../../Widgets/FilterMultiChip';
import FilterChip from '../../../Widgets/FilterChip';
import MessengerValue from '../../../Widgets/MessengerValue';
import LocationValue from '../../../Widgets/LocationValue';
import URLValue from '../../../Widgets/URLValue';
import CKEditorContent from '../../../Widgets/CKEditorContent';
import Avatar from '../../../Widgets/Avatar';
import NodeImage from '../../../Widgets/NodeImage';
import ContentImage from '../../../Widgets/ContentImage';
import ImagesList from '../../../Widgets/ImagesList';
import PriceWidget from '../../../Widgets/Price';
import Link from '../../../Widgets/Link';
import NodeValue from '../../../Widgets/NodeValue';
import Notification from '../../../Widgets/Notification';
import DateValue from '../../../Widgets/DateValue';
import DefaultImage from '../../../Widgets/DefaultImage';
import TextZoomImage from '../../../Widgets/TextZoomImage';
import NotPublishedIcon from '../../../Widgets/NotPublishedIcon';

import {FormFieldData} from '../../../../../Components/FormValues';

import UserHistoryLink from '../../UserHistoryLink';
import UserHistoryLinkByID from '../../UserHistoryLinkByID';
import ConversationAvatar from '../../ConversationAvatar';
import StatusUpdate from '../../StatusUpdate';

import './index.scss'

function getNodeTitle(published, values, value, noChildLinks) {
    return <span className="notPublishedWrap">
        {published ? '' : <NotPublishedIcon />}

        {!noChildLinks && values?._uri && <RouterLink to={restorePath(values?._uri)} onClick={(e) => e.stopPropagation()}>
            <span className='notPublishedTitle'>{value}</span>
        </RouterLink>}

        {(noChildLinks || !values?._uri) && <>
            <span className="notPublishedTitle">{value}</span>
        </>}
    </span>
}

export default function CellContent(props) {
    const {fields, field, values, published, templateOptions} = props;

    const {configs} = useConfigStore();
    const {userState} = useGlobalCustomContext();

    if (!values) return <></>

    const city = getClientCity(window) || DEFAULT_CITY;
    const timeZone = getClientCityTimezone(configs, city);
    const config = fields?.[field];
    const value = values[field];

    if (field === '_reading_by') {
        return <>
            <div className='text'>
                <span>{values?._reading_by?.name} </span>
                {values?._reading_from && <span>з {getFormatedDate(timestampToDate(values?._reading_from), timeZone)} до {getFormatedDate(timestampToDate(values?._reading_till), timeZone)}</span>}
            </div>

            <div className='actions'>
                <EmailButton fullWidth label="Написати листа" email={values?._reading_by.email} />
                <MessageToReader reading={values?._reading_by} />
                <BotNotificationButton fullWidth id={values?._reading_by} />
            </div>

            <div className='deposit'>
                <TextZoomImage
                    label={<Button fullWidth variant='outlined'>Скріншот про сплату <PriceWidget value={values?.deposit} /></Button>}
                >
                    <DefaultImage image={values?._reading_by?.deposit} width="60" zoom />
                </TextZoomImage>
            </div>
        </>
    }

    if (field === 'title') {
        if (config?.type === 'book-title-with-status') {
            return <div>
                <div className='title'>{getNodeTitle(published, values, value, templateOptions?.noChildLinks)}</div>
                <div className='status'>{getBookStatusLabel(values)}</div>
            </div>
        }

        if (config?.type === 'networking-link') {
            return <UserHistoryLink id={values?._created_by} name={getNodeTitle(published, values, value, templateOptions?.noChildLinks)} />
        }

        return getNodeTitle(published, values, value, templateOptions?.noChildLinks);
    }

    if (config?.type === 'user-title') {
        let v = values?.displayName;
        if (values?._error) v += ` (error: ${values?._error})`;
        return v;
    }

    if (config?.type === 'message') {
        if (userState?.user) {
            let className = isYoursAccount(userState, values?._init_by) ? 'CellMessage sender' : 'CellMessage recepient'

            if (config.conversation.conversation && config.conversation.private) {
                className += ' privateConversation'
            }
            else if (config.conversation.conversation && !config.conversation.private) {
                className += ' publicConversation'
            }

            return <div className={className}>
                <div className="avatar">
                    <Avatar image={config.extraData?.[values?._init_by]?.photoURL} />
                </div>

                <div className='text'>
                    <div className='content'>
                        <Notification code={values?._code} values={values} />
                    </div>
                    <div className='timestamp'>
                        <DateValue value={values?._timestamp} />
                    </div>
                </div>
            </div>
        }
        return <Skeleton variant="circular" width={66} height={66} />
    }

    if (config?.type === 'conversation') {
        const title = getConversationTitle(configs, values, userState, config.extraData)

        if (userState?.user) {
            return <div className='CellConversation'>
                <div className='CellConversationTitle'>
                    <RouterLink to={`/profile/messages/${values?._cid}`}>{title}</RouterLink>
                </div>
                <div className='content'>
                    <Notification code={values?._last_message._code} values={values?._last_message} short />
                </div>
                <div className='timestamp'>
                    <DateValue value={values?._updated} />
                </div>
            </div>
        }
        else {
            return <>
                <div style={{marginBottom: '1rem'}}><Skeleton variant="rectangular" width={'60%'} height={16} /></div>
                <div><Skeleton variant="rectangular" width={'40%'} height={16} /></div>
            </>
        }
    }

    if (config?.type === 'order') {
        if (config?.extraData?.link?.partner?.shop) {
            return <RouterLink to={`/profile/history/shop/${config.extraData.link.partner.id}/${config.extraData.link.partner.shop}/${values?._oid}`}>{values?._oid}</RouterLink>
        }
        else if (config?.extraData?.link?.partner?.form) {
            return <RouterLink to={`/profile/history/form/${config.extraData.link.partner.id}/${config.extraData.link.partner.shop}/${values?._oid}`}>{values?._oid}</RouterLink>
        }
        else if (config?.extraData?.partner?.links?.[values._city]?.shop) {
            return <RouterLink to={`/profile/history/shop/${values.partner}/${values.shop}/${values?._oid}`}>{values?._oid}</RouterLink>
        }
        else if (config?.extraData?.partner?.links?.[values._city]?.form) {
            return <RouterLink to={`/profile/history/form/${values.partner}/${values.form}/${values?._oid}`}>{values?._oid}</RouterLink>
        }
    }

    if (config?.type === 'conversation-open') {
        return <RouterLink to={`/profile/messages/${values?._cid}`}>
            <KeyboardArrowRightIcon className='moreBtn' />
        </RouterLink>
    }

    if (config?.type === 'conversation-logo') {
        if (userState?.user) {
            return <ConversationAvatar conversation={values} users={config.extraData} />
        }
        return <Skeleton variant="circular" width={66} height={66} />
    }

    if (config?.type === 'user') {
        return <UserHistoryLink id={value.id} name={value.name} />
    }

    if (config?.type === 'location') {
        return <LocationValue value={value} replaceLocationTitle={templateOptions?.replaceLocationTitle} />
    }

    if (config?.type === 'url') {
        return <URLValue value={value} />
    }

    if (config?.type === 'messenger') {
        return <MessengerValue messenger={value} />
    }

    if (config?.type === 'city') {
        if (!value) return <></>;

        if (value === NO_CITY) return <>Не вказано</>
        if (value === DEFAULT_CITY) return <Chip className='cityLabel' variant="outlined" label="Головна" />
        return <Chip className='cityLabel' variant="outlined" label={configs.content.cities[value].city} />
    }

    if (config?.type === 'filter') {
        const v = config?.options?.elseOption ? value.value : value;

        if (config?.options?.elseOption && v === '_else') {
            return value.else;
        }
        else if (config?.options?.clickable) {
            if (config?.options?.multi) {
                if (!isArray(v)) {
                    return <span className='FilterClickableValue' key={`filter_clickable_value_${v}`}>
                        <FilterChip className="textOnly" clickable value={v} config={config} />
                    </span>
                }

                return v?.map((d, i) => <span className='FilterClickableValue' key={`filter_clickable_value_${d}`}>
                    <FilterChip className="textOnly" clickable value={d} config={config} />
                    {(i + 1) !== v.length && <>,&nbsp; </>}
                </span>)
            }
            
            return <span className='FilterClickableValue'>
                <FilterChip className="textOnly" clickable value={v} config={config} />
            </span>
        } else if (config?.options?.multi) {
            return <FilterMultiChip className="textOnly" values={v} config={config} />
        } else {
            return <FiltertValue value={v} config={config} />
        }
    }

    if (config?.type === 'wysiwyg' || config?.type === 'textarea') {
        return <CKEditorContent content={value} />
    }

    if (config?.type === 'tags') {
        return <>{value.join(', ')}</>;
    }

    if (config?.type === 'price') {
        return <PriceWidget value={value} />
    }

    if (config?.type === 'timestamp') {
        return <DateValue value={value} />
    }

    if (config?.type === 'boolean') {
        const label = config?.options?.labels?.[value];
        if (label) return <>{label}</>;
        if (!label) return <>{value === true ? 'так' : 'ні'}</>;
    }

    if (config?.type === 'node') {
        return <NodeValue value={value} />
    }

    if (config?.type === 'roles') {
        return <>{value.map(role => <Chip key={role} variant="outlined" label={role} />)}</>
    }

    if (config?.type === 'history-user-link') {
        return <UserHistoryLinkByID id={value} />
    }

    if (config?.type === 'shop-total') {
        return CurrencyFormat(totalPrice(values?.data.order), values?.shop_currency);
    }

    if (config?.type === 'exchange-order') {
        return orderTitle(values.formatter, values.data);
    }

    if (config?.type === 'history-details') {
        return <JsonView
            src={value}
            editable={false}
            collapsed={false}
            enableClipboard={false}
        />
    }

    if (config?.type === 'json') {
        return <JsonView
            src={value}
            editable={false}
            collapsed={false}
            enableClipboard={false}
        />
    }

    if (config?.type === 'notification') {
        return <Notification code={value} values={values} />
    }

    if (config?.type === 'request') {
        return <pre dangerouslySetInnerHTML={{ __html: makeUrlsClickable(value) }} className="DetailsBlockWrap"></pre>
    }

    if (config?.type === 'book-status') {
        return getBookStatusLabel(values)
    }

    if (config?.type === 'custom-order-field') {
        if (values.data[config.field]) {
            const partnerConfig = config?.extraData?.partner?.links[values._city];
            return <FormFieldData field={partnerConfig.fields[config.field]} data={values.data[config.field]} ignore={['messenger']} />;
        }
    }

    if (config?.type === 'status') {
        return <StatusUpdate {...props} partner={config?.extraData.partner} />
    }

    if (config?.type === 'exchange-mobile-order') {
        return <ExchangeMobileOrder {...props} />
    }

    // by field name
    if (field === 'creationTime') {
        return <>{dayjs(new Date(value)).format('YYYY-MM-DD HH:mm')}</>;
    }

    if (field === 'facebook') {
        return <Link blank link={getFacebookLink(value)} />
    }

    if (field === 'instagram') {
        return <Link blank link={getInstagramLink(value)} />
    }

    // images
    if (field === 'photoURL') {
        return <Avatar image={value} />
    }

    if (config?.type === 'node_image') {
        return <NodeImage city={values?._city} values={values} className="tableImages" />
    }

    if (config?.type === 'image' || config?.type === 'logo') {
        return <ContentImage src={field} image={value} className="tableImages" />
    }

    if (field === 'gallery') {
        return <ImagesList city={values?._city} content={value} zoom />
    }

    return <>{value}</>
}

function ExchangeMobileOrder(props) {
    const {fields, field, values} = props;

    const config = fields?.[field];

    return <div className='ExchangeMobileOrder'>
        <div className='oid'>
            📄 {values._oid}
            {/* <MarkUnreadChatAltOutlinedIcon onClick={()=>{setOpened(true)}}/> */}
        </div>

        <div className='time'>
            <DateValue value={values?._timestamp} />
        </div>

        <div className='order'>
            {orderTitle(values.formatter, values.data)}
        </div>

        <div className='details'>
            <pre dangerouslySetInnerHTML={{ __html: makeUrlsClickable(values?.data_text) }} ></pre>
        </div>

        {/* <div className='user'><UserHistoryLinkByID id={values._init_by} /></div> */}

        <div className='action'>
            <StatusUpdate {...props} partner={config.extraData.partner} />
        </div>
    </div>
}
