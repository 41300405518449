export default function LocationValue(props) {
    const {value} = props;

    if (!value) return <></>

    return <>
        {(value.link || (value.lng && value.lat)) && <LocationLink {...props}/>}
        {!value.link && !value.lng && !value.lat && <div className="title">{value.title}</div>}
        {value.address && <div className="address">{value.address}</div>}
        {value.comment && <div className="comment">{value.comment}</div>}
    </>
}

function LocationLink(props) {
    const {value, replaceLocationTitle} = props;

    let link;
    if (value.link) link = value.link;
    else if (value.lng && value.lat) link = `https://maps.google.com/?q=${value.lat},${value.lng}`;

    return <a href={link} target='_blank'>{replaceLocationTitle ? "Google Maps" : (value.title || value.link)}</a>;
}
