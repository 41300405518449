import React, {useCallback, useMemo, useEffect, useState} from 'react';
import {isMobile} from "react-device-detect";

import {isEqual} from 'lodash';

import {MESSENGER_COLORS} from 'ultra/const/messengers';

import {isEmptyObj} from 'ultra/helpers/utils';
import {phones} from 'ultra/configs/countries';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import Autocomplete from '@mui/material/Autocomplete';

import InputAdornment from '@mui/material/InputAdornment';

import FormControl from '@mui/material/FormControl';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import './index.scss';

const MESSENGERS = {
    'telegram': TelegramIcon,
    'whatsapp': WhatsAppIcon,
    // 'email': AlternateEmailIcon,
    // 'other': ContactSupportOutlinedIcon,
}

const MESSENGER_NAMES = {
    whatsapp: 'WhatsApp',
    telegram: 'Telegram'
}

// ADD:
// 'Viber'
// 'Signal'

export default function Messenger(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;

    function getMessenger() {
        return (content[field.id] && Object.keys(content[field.id])[0]) || ""
    }

    function getTelegramType() {
        const type = getMessenger();
        if (content[field.id] && content[field.id]?.[type]?.phone) return 'phone';
        if (content[field.id] && content[field.id]?.[type]?.username) return 'username';
 
        return ''
    }

    const [messengerTypeValue, setMessengerTypeValue] = useState(getMessenger())
    const [telegramTypeValue, setTelegramTypeValue] = useState(getTelegramType())
    const [phoneValue, setPhoneValue] = useState({})
    const [usernameValue, setUsernameValue] = useState('')
    const [labelValue, setLabelValue] = useState('')

    const [showSign, setShowSign] = useState(false)

    function setShowSignBlock(value) {
        if (!value) {
            setLabelValue('');
        }

        setShowSign(value)
    }

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    const [className, setClassName] = useState('MessengerField inputEmulation'
        + (isDisabled ? ' disabled' : '')
        + (showErrorText ? ' error' : ''));

    useEffect(() => {
        const messenger = getMessenger();
        const telegramType = getTelegramType();

        if (!isEqual(messengerTypeValue, messenger)) setMessengerTypeValue(messenger);
        if (telegramType && telegramTypeValue != telegramType) setTelegramTypeValue(telegramType);

        if (
            content[field.id]?.[messenger]?.phone?.number !== phoneValue.number
            ||
            content[field.id]?.[messenger]?.phone?.code !== phoneValue.code
        ) {
            setPhoneValue(content[field.id]?.[messenger]?.phone || {});
        }

        if (!isEqual(usernameValue, content[field.id]?.[messenger]?.username)) setUsernameValue(content[field.id]?.[messenger]?.username || '');
        if (!isEqual(labelValue, content[field.id]?.[messenger]?.label)) setLabelValue(content[field.id]?.[messenger]?.label || '');
    }, [content[field.id]])

    useEffect(() => {
        setClassName('MessengerField inputEmulation'
            + (isDisabled ? ' disabled' : '')
            + (showErrorText ? ' error' : ''))
    }, [showErrorText])

    const onMessengerChange = (e) => {
        const messenger = e.target.value.toLowerCase();
        setMessengerTypeValue(messenger);

        const value = {[messenger]: {phone: {}}}
        if (showSign) { value[messenger].label = '' }

        const prevMessenger = getMessenger();
        if (content[field.id]?.[prevMessenger] && !isEmptyObj(content[field.id][prevMessenger])) {
            value[messenger] = content[field.id]?.[prevMessenger];
        }

        onChange({target: {value}})
    }

    const onTelegramTypeChange = (e) => {
        setTelegramTypeValue(e.target.value);

        setPhoneValue({})
        setUsernameValue("")

        const messenger = getMessenger();
        const value = {[messenger]: {}}
        onChange({target: {value}})
    }

    const onPhoneChange = (newValue) => {
        setPhoneValue(newValue)

        const messenger = getMessenger();
        const value = {[messenger]: {phone: newValue}}
        onChange({target: {value}})
    }

    const onUsernameChange = (e) => {
        setUsernameValue(e.target.value)

        // TODO:
        // add minimal 5 symbols

        const messenger = getMessenger();
        const value = {[messenger]: {username: e.target.value}}
        onChange({target: {value}})
    }

    const onAccounKeyDownHandler = (e) => {
        const regex = new RegExp('^[0-9a-zA-Z_]*$');
        const isBackspace = e.keyCode === 8;
        const isArrow = e.keyCode >= 37 && e.keyCode <=40 ;

        if (!isArrow && !isBackspace && !regex.test(e.key)) {
            e.preventDefault();
        }
    }

    const onLabelChange = (e) => {
        setLabelValue(e.target.value)

        const messenger = getMessenger();
        const value = {[messenger]: {label: e.target.value}}
        onChange({target: {value}})
    }

    return (<>
        <div className={className}>
            <Grid container spacing={1}>
                <Grid item xs={12} className='ContactLabel'>
                    {field.placeholder} {field.required ? '*' : ''}
                </Grid>
                <Grid item xs={12}>
                    <div className='ContactControlWrap'>
                        <div className='ContactControlType'>
                            <FormControl disabled={isDisabled} fullWidth className='ContactMessenger'>
                                <Select
                                    value={messengerTypeValue}
                                    onChange={onMessengerChange}>
                                        <MenuItem value="">&nbsp;</MenuItem>
                                        {Object.keys(MESSENGERS).map(value => <MenuItem key={value} value={value}>
                                            <div className='messengerOption'>
                                                {React.createElement(MESSENGERS[value], {sx: {color: MESSENGER_COLORS[value]}})}
                                                {isMobile && <span>{MESSENGER_NAMES[value]}</span>}
                                            </div>
                                        </MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>

                        {messengerTypeValue === 'whatsapp' &&
                            <div className='ContactControlPhone'>
                                <Phone value={phoneValue} onChange={onPhoneChange} isDisabled={isDisabled} />
                            </div>
                        }

                        {messengerTypeValue === 'telegram' && <>
                            <div className='ContactControlType'>
                                <FormControl disabled={isDisabled} fullWidth className='TelegramMessengerType'>
                                    <Select
                                        value={telegramTypeValue}
                                        onChange={onTelegramTypeChange}>
                                            <MenuItem value="">&nbsp;</MenuItem>
                                            <MenuItem value="phone">
                                                Телефон
                                            </MenuItem>
                                            <MenuItem value="username">
                                                Нікнейм
                                            </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            {telegramTypeValue === 'username' && <div className='ContactControlUsername'>
                                <TextField
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">@</InputAdornment>,
                                    }}
                                    className='ContactNumber'
                                    fullWidth
                                    disabled={isDisabled}
                                    value={usernameValue}
                                    // label="Ім'я користувача"
                                    variant="outlined"
                                    // inputProps={{minLength: 5}}
                                    onChange={onUsernameChange}
                                    onKeyDown={onAccounKeyDownHandler}
                                />
                            </div>}

                            {telegramTypeValue === 'phone' && <div className='ContactControlPhone'>
                                <Phone value={phoneValue} onChange={onPhoneChange} isDisabled={isDisabled} />
                            </div>}
                        </>}
                    </div>
                </Grid>
                {/* className='noMargin' */}
                <Grid item xs={12}>
                    {/* <div className='fieldExample'>
                        {telegramTypeValue === 'phone' && 'Приклад: +380991234567'}
                        {telegramTypeValue === 'username' && 'Приклад: @svyatoslav_vokarchuk'}
                    </div> */}

                    {field?.options?.displayLabelField && !isEmptyObj(phoneValue) && <div className='addSign'>
                        {showSign ? '-' : '+'} <span className='pseudoLink' onClick={() => {setShowSignBlock(!showSign)}}>{showSign ? 'Приховати' : 'Додати'} підпис</span>
                    </div>}
                </Grid>

                {showSign && <Grid item xs={12}>
                    {field?.options?.displayLabelField && <TextField
                        className='ContactLabel'
                        fullWidth
                        disabled={isDisabled}
                        value={labelValue}
                        label="Підпис"
                        variant="outlined"
                        onChange={onLabelChange}
                    />}
                    {field?.options?.displayLabelField && <div className='fieldExample'>
                        Приклад: Менеджер
                    </div>}
                </Grid>}
            </Grid>
            {field?.notes && <div className='fieldNotes'>
                <p>{field?.notes}</p>
            </div>}
        </div>
        {showErrorText && <div className='fieldError'>
            {showErrorText}
        </div>}
    </>)
}

function Phone(props) {
    const {isDisabled, value, onChange} = props;
    
    const [codeValue, setCodeValue] = useState({code: 'UA', emoji: '🇺🇦', dial_code: '+380'}) // value?.code || 
    const [numberValue, setNumberValue] = useState('') // value?.number || 

    const [flagHidden, setFlagHidded] = useState(false)

    const sortedPhones = useMemo(() => {
        return phones.sort(( a, b ) => {
            if (Number(a.dial_code) < Number(b.dial_code)){
              return -1;
            }
            if (Number(a.dial_code) > Number(b.dial_code)){
              return 1;
            }
            return 0;
          })
        
    })
    const [options, setOptions] = useState(sortedPhones)

    useEffect(() => {
        if (codeValue?.dial_code !== value?.code) {
            let code = sortedPhones.find(i => i.dial_code === value?.code);
            if (!code) {
                code = {code: 'UA', emoji: '🇺🇦', dial_code: '+380'};
            }

            setCodeValue(code);
        }
        if (numberValue !== value?.number) setNumberValue(value.number);
    }, [value])

    // [...sortedPhones.filter(i => i.dial_code === "+380")]
    // {code: 'UA', emoji: '🇺🇦', dial_code: '+380'}

    // useEffect(() => {
    //     setCodeValue(value?.code || {code: 'UA', emoji: '🇺🇦', dial_code: '+380'});
    //     setNumberValue(value.number || '');
    //     setOptions([...sortedPhones.filter(i => i.dial_code === "+380")]);
    // }, [])

    const onInputChange = useCallback(
        (e) => {
            if (!e?.target?.value) return;

            setFlagHidded(true)
            setOptions([...sortedPhones.filter(i => String(i.dial_code).includes(e?.target?.value))]);
        },
        [options]
    );

    const onNumberChange = (e) => {
        setNumberValue(e.target.value)
        onChange({
            number: e.target.value,
            code: codeValue.dial_code
        })
    }

    const onNumberKeyDownHandler = (e) => {
        const regex = new RegExp('^[0-9]*$');
        const isBackspace = e.keyCode === 8;
        const isArrow = e.keyCode >= 37 && e.keyCode <=40 ;

        if (!isArrow && !isBackspace && !regex.test(e.key)) {
            e.preventDefault();
        }
    }

    const onCodeKeyDownHandler = (e) => {
        const regex = new RegExp('^[0-9+]*$');
        const isBackspace = e.keyCode === 8;
        const isArrow = e.keyCode >= 37 && e.keyCode <=40 ;

        if (!isArrow && !isBackspace && !regex.test(e.key)) {
            e.preventDefault();
        }
    }

    const onValueChange = (e) => {
        setFlagHidded(false)
        setCodeValue(e)
        onChange({
            number: numberValue,
            code: e.dial_code,
        })
    }

    return <div className='PhoneField'>
        <div className='code'>
            {!flagHidden && <span className={`flags`}>{codeValue?.emoji}</span>}
            <Autocomplete
                fullWidth
                disableClearable

                noOptionsText="Відсутній код"

                options={options}

                getOptionLabel={(option) => option?.dial_code || ''}

                value={codeValue}

                disabled={isDisabled}

                onInputChange={onInputChange}

                onChange={(e, value) => {
                    onValueChange(value)
                }}

                isOptionEqualToValue={(option, value) => {
                    return option?.dial_code === value?.dial_code
                }}

                renderInput={(params) => <TextField {...params} onKeyDown={onCodeKeyDownHandler} label="Код" />}

                renderOption={(props, option) => {
                    const {key, ...optionProps} = props;
                    return <Box
                        key={'select_flag_' + option.code} {...optionProps}
                        component="li"
                        className={`CountriesListItem`}
                    >
                        <span className='flags'>
                            {option.emoji}
                        </span>
                        <span className='code'>{option.dial_code}</span>
                    </Box>
                }}
            />
        </div>

        <div className='text'>
            <TextField
                className='ContactNumber'
                fullWidth
                disabled={isDisabled}
                value={numberValue}
                label="Номер"
                variant="outlined"
                onChange={onNumberChange}
                onKeyDown={onNumberKeyDownHandler}
            />
        </div>
    </div>
}
