import {isMobile} from "react-device-detect";

import React, {useEffect, useState, useMemo} from 'react';
import {useLoaderData, useNavigation, useParams} from "react-router-dom";

import {setPageTitle} from '../../../../../Helpers/router';
import {useGlobalCustomContext} from '../../../../../Helpers/context';
import {getFormOrderHistory} from '../../../Helpers/history';

import {useConfigStore} from '../../../../../Stores/config';

// import PreloaderTable from '../../../../../Components/PreloaderTable';
import FormValues from '../../../../../Components/FormValues';

import BreadcrumbsWidget from '../../../../Content/Widgets/Breadcrumbs';
import TitleWidget from '../../../../Content/Widgets/Title';
import FiltersLine from '../../../../Content/Widgets/FiltersLine';
import StatusUpdate from '../../../../Content/Widgets/StatusUpdate';
import DateValue from '../../../../Content/Widgets/DateValue';

import {useBackdropPreloaderStore} from '../../../../../Stores/backdropPreloader';

import {NODE_ACTIONS} from '../../../../Content/Reducers/node';

import './index.scss';

export default () => {
    const initOrder = useLoaderData();

    let {partner, form, order} = useParams();

    const navigation = useNavigation();
    const {configs} = useConfigStore();

    const {nodeState, nodeDispatch} = useGlobalCustomContext();
    const {hideBackdropPreloader} = useBackdropPreloaderStore();
    const {userState} = useGlobalCustomContext();

    const [history, setHistory] = useState();
    const [config, setConfig] = useState();
    const [permits, setPermits] = useState();
    const [breadcrumbs, setBreadcrumbs] = useState({});

    useEffect(() => {
        setPermits({})
        setPageTitle('Історія замовленя')
    }, [])

    useEffect(() => {
        if (nodeState.updatePageContentStart) {
            nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_END});
            updatePage()
        }
    }, [nodeState])

    useEffect(() => {
        if (navigation.state === 'idle') {
            updateOrder(initOrder);
        }
    }, [navigation])

    useEffect(() => {
        const config = {templateOptions: {}}

        if (configs) {
            setConfig({...config})
        }

        const b = {}
        b[`\\profile\\history\\form\\${partner}\\${form}`] = {title: userState?.user?.partnership?.forms?.find(i => i.form === form)?.title}
        setBreadcrumbs(b)
    }, [userState?.user, configs])

    function updateOrder(h) {
        setHistory({...h})
        hideBackdropPreloader()
    }

    const content = useMemo(() => {
        if (!history) return {}
        return {
            title: history?.data?._oid
        }
    }, [history])

    const updatePage = () => {
        const url = new URL(window.location.href);

        updateOrder({});
        getFormOrderHistory(partner, form, order, url.search)
            .promise
            .then(updateOrder)
    }

    return <div id="Content" className='Content'>
            <div className='HistoryFormOrderAdminPage'>
                {history && <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} />}
                {history && <BreadcrumbsWidget hideRoot links={breadcrumbs} hide={content.hideBreadcrumbs} />}

                {isMobile && <div className='MobileFiltersLine'>
                    <FiltersLine
                        size="small"
                        filters={config?.showFilters?.filterByInline}
                    />
                </div>}

                {/* {<PreloaderTable/>} */}

                {history?.data && <>
                    <div className="labelWrap labelWrapRow">
                        <div className="labelValue">
                            <DateValue value={history.data._timestamp} />
                        </div>                    
                    </div>

                    <div className="labelWrap">
                        <div className="label">
                            Статус:
                        </div>
                        <div className="labelValue">
                            {history.data.status === 'new' && '📥 Нова заявка'}
                            {history.data.status === 'in_progress' && '🔄 Заявка в обробці'}
                            {history.data.status === 'success' && '✅ Успішно заврешено'}
                            {history.data.status === 'canceled' && '❌ Заявку скасовано'}
                        </div>                    
                    </div>

                    <FormValues config={history.config.links[history.data._city]} data={history.data.data} />

                    <StatusUpdate hideStatus fields={history?.config?.fields} field={"status"} values={history.data} partner={history.config} />
                </>}
            </div>
        </div>
}
