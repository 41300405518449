import React from 'react';
import {isArray} from 'lodash';
import Rating from '@mui/material/Rating';
import {CurrencyFormat} from 'ultra/helpers/utils';

import MessengerValue from '../../Modules/Content/Widgets/MessengerValue';
import FilterValue from '../../Modules/Content/Widgets/FilterValue';
import DefaultImage from '../../Modules/Content/Widgets/DefaultImage';
import TextZoomImage from '../../Modules/Content/Widgets/TextZoomImage';
import LocationValue from '../../Modules/Content/Widgets/LocationValue';

import './index.scss';

export default function FormValues(props) {
  const {config, data, ignore = []} = props;

  if (!config || !data) return <></>;

  const nonOrderedFields = []
  Object.keys(config.fields)
    .filter(field => !ignore.includes(field))
    .map(field => {
        if (!config.fieldsOrder.includes(field)) nonOrderedFields.push(field)
    })

  return <div className='FormValues'>
    {config.fieldsOrder
        .filter(field => Boolean(data[field]) && !ignore.includes(field))
        .map(field =>
            <div key={`form_values_${field}`} className={`field field_${config.fields[field].type}`}>
                <FormValuesData field={config.fields[field]} data={data[field]}  />
            </div>
        )}
    {nonOrderedFields
        .sort()
        .map(field =>
            <div key={`form_values_${field}`} className={`field field_${config.fields[field].type}`}>
                <FormValuesData field={config.fields[field]} data={data[field]}  />
            </div>
        )}
  </div>
}

function FormValuesData(props) {
    const {field, data} = props;

    if (!data) return <></>

    switch (field.type) {
        case 'filter':
            return <div className={`labelWrap ${isArray(data) && data.length > 1 ? 'labelWrapRow' : ''}`}>
                <div className='label'>{field.placeholder}:</div>
                {isArray(data) && <div className='labelValue'>
                    <FormFieldData {...props} />
                </div>}
                {!isArray(data) && <FormFieldData {...props} />}
            </div>

        case 'gallery':
            return <div className='labelWrap labelWrapRow'>
                <div className='label'>{field.placeholder}:</div>
                {isArray(data) && <div className='labelValue gallery'>
                    <FormFieldData {...props} />
                </div>}
                {!isArray(data) && <FormFieldData {...props} />}
            </div>

        default:
            return <div className='labelWrap'>
                <div className='label'>{field.placeholder}:</div>
                <div className='labelValue'><FormFieldData {...props} /></div>
            </div>
    }
}

export function FormFieldData(props) {
    const {field, data} = props;

    if (!data) return <></>

    switch (field.type) {
        case 'location':
            return <LocationValue value={data} />

        case 'rate':
            return <Rating precision={0.5} readOnly value={data} size="small" />

        case 'checkbox':
            if (data) return 'так';
            else return 'ні';

        case 'messenger':
            return <MessengerValue messenger={data} />

        case 'filter':
            const v = field?.options?.elseOption ? data.value : data;
            if (field?.options?.elseOption && v === '_else') {
                return data.else;
            }

            if (isArray(v)) {
                return v.map((i, j) => <span key={`form_value_img_${i}`}>
                    <FilterValue  value={i} config={field} />
                    {j !== (v.length - 1) && <>, </>}
                </span>)
            }
            else {
                return <FilterValue value={v} config={field} />
            }

        case 'gallery':
            if (isArray(data) ) {
                return data?.map(i => <DefaultImage key={`form_value_gallery_img_${i}`} className='photo' image={i} zoom />);
            }
            else {
                return <DefaultImage className='gallery-photo' image={data} zoom />;
            }

        case 'image':
            return <TextZoomImage image={data} className='photo' label='показати' />

        case 'partnerPayments':
            let index = Object.keys(field.config).filter(i => field.config[i].id === data)
            return field.config[index].label

        case 'price':    
            return CurrencyFormat(data.price, data.currency)

        default:
            return data
    }
}
