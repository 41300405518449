import {isMobile} from "react-device-detect";
import React, {useMemo, useEffect, useState, useCallback} from 'react';
import {APIProvider, Map, AdvancedMarker, useMapsLibrary, useMap} from '@vis.gl/react-google-maps';

import {isEqual} from 'lodash';

import {getClientConfig} from 'ultra/configs/general';
import {getClientCity, getCityConfig} from 'ultra/configs/general';

import Autocomplete from '@mui/material/Autocomplete';

import LocationOnIcon from '@mui/icons-material/LocationOn';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import MyLocationIcon from '@mui/icons-material/MyLocation';
// import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ViewDayOutlinedIcon from '@mui/icons-material/ViewDayOutlined';
// import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
// import MenuIcon from '@mui/icons-material/Menu';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import {useConfigStore} from '../../../Stores/config';

import './index.scss';

export default function Location(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;

    const {configs} = useConfigStore();
    const [cityConfig] = useState(getCityConfig(configs, getClientCity(window)));

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable));
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled]);

    const [title, setTitle] = useState('');
    const [address, setAddres] = useState('');
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');
    const [link, setLink] = useState('');
    const [comment, setComment] = useState('');

    const className = useMemo(() => {
        return 'Location'
        + (isDisabled ? ' disabled' : '')
        + (showErrorText ? ' error' : '')
        + (field?.options?.extended ? ' extended' : '')
    }, [isDisabled, showErrorText, field]);

    const [selectedPlace, setSelectedPlace] = useState(null);
    const [defaultCenter, setDefaultCenter] = useState({ lat: Number(content?.[field.id]?.lat || cityConfig.location.lat), lng: Number(content?.[field.id]?.lng || cityConfig.location.lng) });
    const [defaultZoom, setDefaultZoom] = useState(content?.[field.id]?.lat ? 16 : cityConfig.location.zoom);
    const [controlType, setControlType] = useState();
    const [showHowToGetCoordinates, setShowHowToGetCoordinates] = useState();

    const [zoom, setZoom] = useState();
    const [center, setCenter] = useState();

    const {firebase} = getClientConfig(window);

    useEffect(() => {
        const newValue = {...content[field.id]} || {}
        if (!isEqual(title, newValue.title)) setTitle(newValue.title || '');
        if (!isEqual(address, newValue.address)) setAddres(newValue.address || '');
        if (!isEqual(lat, newValue.lat)) setLat(newValue.lat || '');
        if (!isEqual(lng, newValue.lng)) setLng(newValue.lng || '');
        if (!isEqual(link, newValue.link)) setLink(newValue.link || '');
        if (!isEqual(comment, newValue.comment)) setComment(newValue.comment || '');
    }, [content[field.id]])

    const getDataObject = () => {
        if (!controlType) return {}

        const result = {type: controlType, title, comment}
        if (lat) result.lat = lat;
        if (lat) result.lng = lng;

        // if (controlType === 'location') {
        // }
        if (controlType === 'search') {
            if (link) result.link = link;
        }
        if (controlType === 'manual') {
            if (address) result.address = address
        }
        return result;
    }

    const onTitleChange = (e) => {
        let result = getDataObject()
        result.title = e.target.value
        onChange({target: {value: result}})
    }

    const onAddressChange = (e) => {
        let result = getDataObject()
        result.address = e.target.value
        onChange({target: {value: result}})
    }

    const onLatitudeChange = (e) => {
        let result = getDataObject()
        result.lat = e.target.value

        onChange({target: {value: result}})
    }

    const onLongitudeChange = (e) => {
        let result = getDataObject()
        result.lng = e.target.value
        onChange({target: {value: result}})
    }

    const onCommentChange = (e) => {
        let result = getDataObject()
        result.comment = e.target.value
        onChange({target: {value: result}})
    }

    const onSearchResultSelect = (data) => {
        const value = {
            type: controlType,
            title: data.name,
            link: data.url,
            lat: data.geometry.location.lat(),
            lng: data.geometry.location.lng(),
        };

        setTitle(value.title);
        setLat(value.lat);
        setLng(value.lng);
        setLink(value.link);

        setSelectedPlace(data);

        setCenter({lat: value.lat, lng: value.lng});

        onChange({target: {value}});
    }

    const clearValues = () => {
        if (controlType === 'location') {
            let result = getDataObject();
            delete result.title;
            delete result.comment;
            onChange({target: {value: result}});
        }
        else {
            if (defaultZoom && cityConfig.location.zoom) setDefaultZoom(cityConfig.location.zoom);
            if (defaultCenter && cityConfig.location.lat && cityConfig.location.lng) setDefaultCenter({ lat: Number(cityConfig.location.lat), lng: Number(cityConfig.location.lng) });

            let result = controlType ? {type: controlType} : {};
            onChange({target: {value: result}});
        }
    }

    const onTypeChange = (e) => {
        clearValues();

        setControlType(e.target.value);

        if (e.target.value === 'location') {
            const options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            };
            
            function success(pos) {
                const crd = pos.coords;
                const value = {
                    type: e.target.value,
                    lat: crd.latitude,
                    lng: crd.longitude,
                    // title: data.name,
                    // link: data.url,
                    // ?? add comment
                };
        
                setLat(value.lat);
                setLng(value.lng);
                setZoom(16);
                setCenter({lat: value.lat, lng: value.lng});
        
                onChange({target: {value}});
            }
            
            function error(err) {
                console.warn(`ERROR(${err.code}): ${err.message}`);
            }
    
            navigator.geolocation.getCurrentPosition(success, error, options);
        }

        else {
            let result = controlType ? {type: controlType} : {};
            onChange({target: {value: result}});
        }
    }

    const hasData = (data) => {
        if (!data) return false;
        if (controlType === 'location') {
            if (data.title || data.comment) return true
        }
        else if (data.title || data.address || data.lat || data.lng || data.link || data.comment) return true
        return false;
    }

    return (<>
        <div className={className}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className='FormFieldsGroupLabel'>
                        {field.placeholder} {field.required ? '*' : ''}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className='FormFieldsGroup'>
                        <Grid container spacing={2}>
                            {field?.options?.extended && <Grid item xs={controlType ? 9 : 12} md={controlType ? 10 : 12}>
                                <div className='GeoLocationButtons'>
                                    <Select
                                        value={controlType}
                                        onChange={onTypeChange}>
                                            <MenuItem value="">&nbsp;</MenuItem>
                                            <MenuItem value="search">
                                                <div className='locationFieldOption'>
                                                    <LocationOnOutlinedIcon />
                                                    <span className='text'>Знайти на Google Maps</span>
                                                </div>
                                            </MenuItem>
                                            <MenuItem value="location">
                                                <div className='locationFieldOption'>
                                                    <MyLocationIcon />
                                                    <span className='text'>Ваша локація</span>
                                                </div>
                                            </MenuItem>
                                            <MenuItem value="manual">
                                                <div className='locationFieldOption'>
                                                    <ViewDayOutlinedIcon />
                                                    <span className='text'>Ввести дані вручну</span>
                                                </div>
                                            </MenuItem>
                                    </Select>
                                </div>
                            </Grid>}

                            {controlType && <Grid item xs={3} md={2}>
                                <Button disabled={!hasData(content[field.id])} startIcon={<CloseIcon />} className="ClearBtn" fullWidth variant="outlined" onClick={clearValues}>{isMobile ? '' : 'Очистити'}</Button>
                            </Grid>}

                            {(
                                !field?.options?.extended
                                ||
                                (field?.options?.extended && (controlType === 'search' || controlType === 'location'))
                            )
                            && <Grid item xs={12}>
                                <div className='Map'>
                                    <APIProvider apiKey={firebase.apiKey}>

                                        <Search onPlaceSelect={onSearchResultSelect} lat={lat} lng={lng} hideSearch={field?.options?.extended && controlType !== 'search'} />
                                        {/* , field, setZoom, setCenter, setLat, setLng, onChange */}
                                        {/* field={field} setZoom={setZoom} setCenter={setCenter} setLat={setLat} setLng={setLng} onChange={onChange} */}

                                        <Map
                                            mapId={firebase.mapId}
                                            style={{
                                                width: '100%',
                                                height: '300px',
                                                borderRadius: '15px',
                                                overflow: 'hidden'
                                            }}
                                            defaultCenter={defaultCenter}
                                            defaultZoom={defaultZoom}
                                            zoom={zoom}
                                            center={center}
                                            gestureHandling={'cooperative'}
                                            disableDefaultUI={true}
                                        >
                                            {lat && lng && <AdvancedMarker
                                                position={{
                                                    lat: Number(lat),
                                                    lng: Number(lng)
                                                }}
                                            />}
                                        </Map>

                                        <MapHandler place={selectedPlace} />

                                    </APIProvider>
                                </div>
                            </Grid>}

                            {(
                                (!field?.options?.extended && lng && lat)
                                ||
                                (field?.options?.extended && controlType === 'location')
                                ||
                                (field?.options?.extended && controlType === 'manual')
                                ||
                                (field?.options?.extended && controlType === 'search' && lat && lng)
                            ) &&
                                // lat && lng &&
                                // !field?.options?.minimal
                                <Grid item xs={12}>
                                    <TextField
                                        error={Boolean(showErrorText)}
                                        fullWidth
                                        disabled={isDisabled}
                                        // || field?.options?.minimal
                                        value={title}
                                        label="Назва локації"
                                        required
                                        variant="outlined"
                                        onChange={onTitleChange}
                                    />
                                </Grid>
                            }

                            {!field?.options?.manualCoordinates && controlType === 'manual' && 
                                <Grid item xs={12}>
                                    <TextField
                                        error={Boolean(showErrorText)}
                                        fullWidth
                                        disabled={isDisabled}
                                        // || field?.options?.minimal
                                        value={address}
                                        label="Адреса"
                                        variant="outlined"
                                        required
                                        multiline
                                        minRows={1}
                                        onChange={onAddressChange}
                                    />
                                </Grid>
                            }

                            {/* !field?.options?.minimal &&  */}
                            {field?.options?.manualCoordinates && controlType === 'manual' && <Grid item xs={6} md={6}>
                                <TextField
                                    error={Boolean(showErrorText)}
                                    fullWidth
                                    disabled={isDisabled}
                                    value={lat}
                                    label="Широта/Latitude"
                                    variant="outlined"
                                    onChange={onLatitudeChange}
                                />
                            </Grid>}

                            {/* !field?.options?.minimal &&  */}
                            {field?.options?.manualCoordinates && controlType === 'manual' && <Grid item xs={6} md={6}>
                                <TextField
                                    error={Boolean(showErrorText)}
                                    fullWidth
                                    disabled={isDisabled}
                                    value={lng}
                                    label="Довгота/Longitude"
                                    variant="outlined"
                                    onChange={onLongitudeChange}
                                />
                            </Grid>}

                            {field?.options?.manualCoordinates && controlType === 'manual' && <Grid item xs={12}>
                                <p className='howToGetCoordinates'>❓ <span className='pseudoLink' onClick={() => setShowHowToGetCoordinates(!showHowToGetCoordinates)}>Як отримати координати на Google Maps</span></p>
                                {showHowToGetCoordinates && <ol className='showHowToGetCoordinates'>
                                    <li>Знайдіть локацію на мапі</li>
                                    <li>Натисніть правою кнопокю на локації</li>
                                    <li>В меню що з'явилось на екрані будуть відображені координати, наприклад: -8.644709309665844, 115.14111461167892</li>
                                    <li>Натисніть на число і вони будуть скопійовані в буфер</li>
                                    <li>Перша цифра це Широта/Latitude, друга цифра це Довгота/Longitude</li>
                                </ol>}
                                </Grid>}

                            {(
                                (!field?.options?.extended && lng && lat)
                                ||
                                (field?.options?.extended && controlType === 'location')
                                ||
                                (field?.options?.extended && controlType === 'manual')
                                ||
                                (field?.options?.extended && controlType === 'search' && lat && lng)
                            ) && field?.options?.comment
                                && <Grid item xs={12}>
                                    <TextField
                                        error={Boolean(showErrorText)}
                                        fullWidth
                                        disabled={isDisabled}
                                        value={comment}
                                        label="Коментар"
                                        variant="outlined"
                                        onChange={onCommentChange}
                                        multiline
                                        minRows={2}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
        {showErrorText && <div className='fieldError'>
            {showErrorText}
        </div>}
    </>)
}

const MapHandler = ({place}) => {
    const map = useMap();
  
    useEffect(() => {
      if (!map || !place) return;
  
      if (place.geometry?.viewport) {
        map.fitBounds(place.geometry?.viewport);
      }
    }, [map, place]);
  
    return null;
};

const Search = (props) => {
    const {onPlaceSelect, lat, lng, hideSearch} = props;

    const map = useMap();
    const places = useMapsLibrary('places');

    const [value, setValue] = React.useState(null);

    const [autocompleteService, setAutocompleteService] = useState(null);
    const [placesService, setPlacesService] = useState(null);
    const [predictionResults, setPredictionResults] = useState([]);
    const [sessionToken, setSessionToken] = useState();

    useEffect(() => {
        if (!places || !map) return;
    
        setAutocompleteService(new places.AutocompleteService());
        setPlacesService(new places.PlacesService(map));
        setSessionToken(new places.AutocompleteSessionToken());
    
        return () => setAutocompleteService(null);
    }, [map, places]);

    useEffect(() => {
        if (!lat) setValue(undefined);
    }, [lat]);

    const fetchPredictions = useCallback(
        async (inputValue) => {
          if (!autocompleteService || !inputValue) return;

          const request = {input: inputValue, sessionToken};
          const response = await autocompleteService.getPlacePredictions(request);

          setPredictionResults(response.predictions);
        },
        [autocompleteService, sessionToken]
    );

    const onInputChange = useCallback(
        (e) => {
            fetchPredictions(e?.target?.value);
        },
        [fetchPredictions]
    );

    return <div className='Search'>
        <Autocomplete
            onInputChange={onInputChange}
            value={value}

            autoComplete
            includeInputInList

            options={predictionResults}
            noOptionsText="Відсутні локації"

            onChange={
                (e, newValue) => {
                    if (!places || !newValue) return;

                    const detailRequestOptions = {
                        placeId: newValue.place_id,
                        fields: ['url', 'geometry', 'name', 'formatted_address'], // 'photo', 
                        sessionToken
                    };

                    const detailsRequestCallback = (placeDetails) => {
                        onPlaceSelect(placeDetails);
                        setSessionToken(new places.AutocompleteSessionToken());
                    };
                
                    placesService?.getDetails(detailRequestOptions, detailsRequestCallback);

                    setValue(newValue);
                }
            }

            renderInput={(params) => <>
                    {!hideSearch &&  <TextField {...params} label="Пошук локації" fullWidth />}
                </>
            }

            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.description
            }

            renderOption={(props, option) => {
                const {key, ...optionProps} = props;
                return (
                    <li key={key} {...optionProps}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                <div className='LocationSearchResultItem'>
                                    <div className='title'>
                                        {option.structured_formatting.main_text}
                                    </div>
                                    <div className='details'>
                                        {option.structured_formatting.secondary_text}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
    />
    </div>
}
