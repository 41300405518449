import React from 'react';
import ReactDOM from 'react-dom/client';
import Skeleton from '@mui/material/Skeleton';

import {getCityFromSubdomain} from 'ultra/configs/general';

import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import {routes} from './routes';
import {routes as contentRoutes} from './Modules/Content/routes';
import {routes as profileRoutes} from './Modules/Profile/routes';

import {telegramLogin} from './Modules/Profile/Helpers/bot';

import PreloaderLinksBlocks from './Components/PreloaderLinksBlocks';
import PreloaderForm from './Components/PreloaderForm';
import Preloader from './Components/Preloader';

// import {} from './Helpers/mode'; // isEmbededMode
import {getTelegramMode, setTelegramUser, cleanTelegramUser} from './Helpers/telegram';
import {isRenderedOnSamePage} from './Helpers/loader';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './common.scss';
import './layout.scss';
import './mui.scss';

import './style.scss';
import './pages.scss';

import 'dayjs/locale/uk';

let bot = getTelegramMode();

function RenderContent () {
  // IT SHOULD RUN ONLY ON START
  if (isRenderedOnSamePage()) {
    const subdomaincity = getCityFromSubdomain(window);

    // if it's not main page, set up city
    if (subdomaincity) {
      // when no city in store
      if (!localStorage.getItem('city')) {
          localStorage.setItem('city', subdomaincity);
      }

      // when different city in stop
      else if (subdomaincity !== localStorage.getItem('city')) {
          localStorage.setItem('city', subdomaincity);
      }
    }
  }

  root.render(
    <React.StrictMode>
      <RouterProvider
        router={router}
        fallbackElement={getPreloader()}
      />
    </React.StrictMode>
  );
}

function getPreloader() {
  if (bot) {
    return <Preloader />
  }

  const isRootPage = window.location.pathname === '/'
  const isProfilePage = window.location.pathname.indexOf('/profile') === 0

  return <div className='contentWrap'>
      <div className="pageTop">
        <div className="subheader">
          <div className='subheaderWrap'>
            <span className='logo'>
              <img className='logoImg' src="/img/logo.png" />
              <span>
                ПРОСТІР
              </span>
            </span>
            <span className='separator'>
            /
            </span>
          </div>
          <div className="user">
            <Skeleton variant="circular" width={30} height={30} />
          </div>
        </div>

        {isRootPage && <div className="BannerCarousel MainBannerCarousell">
          <Skeleton className='BannerPreloader' variant="rectangular" style={{borderRadius: '10px'}} />
          <div style={{textAlign: 'center', height: '100px', textAlign: 'center', marginTop: '.5rem'}}>
            <Skeleton variant="circular" width={10} height={10} style={{display: 'inline-block', margin: '0 3px'}} />
            <Skeleton variant="circular" width={10} height={10} style={{display: 'inline-block', margin: '0 3px'}} />
            <Skeleton variant="circular" width={10} height={10} style={{display: 'inline-block', margin: '0 3px'}} />
          </div>
        </div>}
    </div>

    <div className="AppWrap">
        <div className='Content Preloading'>
          {isRootPage && <div className='LinksTemplate'>
            <PreloaderLinksBlocks />
          </div>}
          {isProfilePage && <PreloaderForm />}
          {!isRootPage && !isProfilePage && <div className='LinksTemplate'>
            <PreloaderLinksBlocks />
          </div>}
        </div>
    </div>
  </div>
}

cleanTelegramUser()

contentRoutes.map(route => routes[0].children.push(route))
profileRoutes.map(route => routes[0].children.push(route))

const router = createBrowserRouter(routes, {
  basename: "/",
});

const root = ReactDOM.createRoot(document.getElementById('root'));

const afterTelegramLoaded = () => {
  const app = window.Telegram.WebApp;

  app.onEvent('viewportChanged', (e) => {
    const VIEWPORT_VS_CLIENT_HEIGHT_RATIO = 0.75;

    if (e.isStateStable) {
      (app.viewportHeight / window.innerHeight) < VIEWPORT_VS_CLIENT_HEIGHT_RATIO ?
        document.body.classList.add('virtual-keyboard-attached')
        : document.body.classList.remove('virtual-keyboard-attached')
    }
  })

  if (bot) {
    app.setHeaderColor('#333333')
    app.setBackgroundColor('#FFFFFF')
    // TODO: add preloader here
  }

  if (Number(app.version) >= 7.7) {
    app.isVerticalSwipesEnabled = false;
    app.disableVerticalSwipes();
  }
  else {
    function preventCollapse() {
      if (window.scrollY === 0) {
        window.scrollTo(0, 1);
      }
    }

    const scrollableElement = document.querySelector("#root");
    scrollableElement.addEventListener("touchstart", preventCollapse);
  }

  telegramLogin(bot, {init: app.initData})
    .then(async data => {
      if (data) {
        if (data.message) {
          app.showAlert(data.message);
        }
        else {
          setTelegramUser(data);

          RenderContent();

          app.expand();
          app.ready();
        }
      }
      else {
        // app.close();
      }
    })
    .catch(() => {
      // app.close();
    })
}

if (bot) {
  let scriptEl = document.createElement("script");
  scriptEl.setAttribute("src", "https://telegram.org/js/telegram-web-app.js");
  scriptEl.async = false;
  scriptEl.addEventListener("load", afterTelegramLoaded);

  document.body.appendChild(scriptEl);
}
else {
  RenderContent()
}
