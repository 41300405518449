import {isUndefined} from "lodash";
import {isMobile} from "react-device-detect";

import ContentImage from '../ContentImage'
import Carousel from '../../../../Components/Carousel';

// https://swiperjs.com/demos#thumbs-gallery-loop

import './index.scss';

export default function Gallery(props) {
    const {content, zoom, className, navigation, slidesPerView} = props;

    if (!content) return <></>

    return <div className={className}>
        {content && <Carousel className="CarouselPhotos" navigation={isUndefined(navigation) ? !isMobile : navigation} slidesPerView={slidesPerView ? slidesPerView : isMobile ? 1 : 2}>
            {content.map(i => <ContentImage zoom={zoom} key={i} image={i} className="image" />)}
        </Carousel>}
    </div>
}
