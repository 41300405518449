import {getClientConfig} from 'ultra/configs/general';
import {post} from '../../../Helpers/http';

const {api} = getClientConfig(window)

export function updateShopOrder(partner, shop, oid, status) {
    return post(`${api}/order/shop/${partner}/${shop}/${oid}/${status}`);
}

export function updateFormOrder(partner, form, oid, status) {
    return post(`${api}/order/form/${partner}/${form}/${oid}/${status}`);
}

export function notifyShopOrder(partner, shop, oid) {
    return post(`${api}/order/notify/shop/${partner}/${shop}/${oid}`);
}

export function notifyFormOrder(partner, form, oid) {
    return post(`${api}/order/notify/form/${partner}/${form}/${oid}`);
}