import {useEffect, useState} from 'react';

import TelegramIcon from '@mui/icons-material/Telegram';

import {getTelegramContact, getWhatAppContact, getTelegramLink, getWhatAppLink} from 'ultra/helpers/messengers';
import {isEmptyMessengerData} from 'ultra/helpers/utils';

import './index.scss'
import WhatsApp from '@mui/icons-material/WhatsApp';

export default function MessengerValue(props) {
    const {messenger} = props;

    const [link, setLink] = useState();
    const [value, setValue] = useState();

    useEffect(() => {
        if (messenger?.telegram) {
            setLink(getTelegramLink(getTelegramContact(messenger.telegram, false))?.href);
            setValue(getTelegramContact(messenger.telegram));
        }
        else if (messenger?.whatsapp) {
            setLink(getWhatAppLink(getWhatAppContact(messenger.whatsapp))?.href);
            setValue(getWhatAppContact(messenger.whatsapp));
        }
    }, [])

    if (isEmptyMessengerData(messenger)) return <></>

    return <span className='MessengerValue'>
        {messenger?.telegram && <>
            <TelegramIcon className='Icon' />
            <a className='link' href={link} target="_blank">{messenger?.telegram?.label || value}</a>
        </>}

        {messenger?.whatsapp && <>
            <WhatsApp className='Icon' />
            <a className='link' href={link} target="_blank">{messenger?.whatsapp?.label || value}</a>
        </>}
    </span>
}
