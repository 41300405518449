exports.MESSENGER_COLORS = {
    "telegram": "#1fa0e1",
    "whatsapp": "#4caf50",
};
exports.FORMATTER = {
    LIBRARY: "library",
    EXCHANGE: "exchange",
    EXCHANGE_OFFER: "exchange_offer",
    NETWORKING: "networking",
    NETWORKING_OFFER: "networking_offer",
};
