import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity, getClientConfig} from 'ultra/configs/general';
import {isEmptyMessengerData} from 'ultra/helpers/utils';

import {patch, get, del} from './http';
import {getTelegramUserName, getTelegramUserId, getTelegramDisplayName} from './telegram';

const {api} = getClientConfig(window)

export function getFormContent(path, search) {
    let link = `${api}/form/${path.city}/path${path.uri}`;
    if (search) link += search
    return get(link);
}

export function getFormByDIDContent({city, did}) {
    let link = `${api}/form/${city}/did/${did}`;
    return get(link);
}

export function getNodeContent(path, search, prevDID) {
    let link = `${api}/content/${path.city}/path${path.uri}`;
    if (search) link += search

    if (prevDID) {
        if (search) link += '&';
        else link += '?';

        link += 'prev=' + prevDID;
    }

    return get(link);
}

export function getNodeByDIDContent(path) {
    let link = `${api}/content/${path.city}/did${path.did}`;
    return get(link);
}

export async function removeContent(path) {
    return del(`${api}/content/${path.city}/path${path.uri}`)
}

export function getNodeLinks(path) {
    return get(`${api}/content/${path.city}/utils/links${path.uri}`);
}

export async function updateContent(path, body) {
    return patch(`${api}/content/${path.city}/path${path.uri}`, body)
}

export async function validateContent(path) {
    return patch(`${api}/content/${path.city}/utils/validate${path.uri}`)
}

export function getPathByDID(city, did) {
    return get(`${api}/content/${city}/utils/uri/${did}`);
}

export function getTags(city, type, fieldId) {
    return get(`${api}/tags/${city}/${type}/${fieldId}`)
}

// helpers
export function getTagsFilter(city, type, fieldId) {
    return new Promise((resolve, reject) => getTags(city, type, fieldId)
        .promise
        .then(values => {
            const result = {}
            values?.tags?.map(i => {
                result[i] = i;
            })

            resolve(result)
        })
        .catch(reject)
    )
}

export function getNodeTypeTitle(title, pre = '', firstLetterCaps) {
    if (!title) return;

    let result = title.toLowerCase();

    function changeLastLetter(str) {
        const lastSymbolIndex = str.length;

        // лікарня
        if (str.slice(-3) != 'ння' && str.slice(-1) === 'я') {
            return str.substring(0, lastSymbolIndex - 1) + 'ю'
        }
    
        // форма
        if (str.slice(-1) === 'а') {        
            return str.substring(0, lastSymbolIndex - 1) + 'у'
        }

        // лікар
        if (str.slice(-3) === 'кар') {        
            return str.substring(0, lastSymbolIndex - 3) + 'каря'
        }

        // посилання
        // страхування
        return str
    }

    const name = result.split(' ').reduce((res, part) => {
            return res += changeLastLetter(part) + ' ';
        }, '').trim();
    
    let res

    if (pre) {
        res = pre + ' ' + name;
    } else {
        res = name;
    }

    if (firstLetterCaps && res) {
        res = res[0].toUpperCase() + res.substring(1)
    }

    return res;
}

export function getDefaultValues(content = {}, config, userState, isEditMode) {
    const initContent = content;

    Object.keys(config.fields).map(fieldId => {
        if (config.fields[fieldId]?.default) {
            if (config.fields[fieldId].default === 'template:location.href') {
                initContent[fieldId] = window.location.href;
            }

            else if (config.fields[fieldId].default === 'template:location.city') {
                initContent[fieldId] = getClientCity(window) || DEFAULT_CITY;
            }

            else if (config.fields[fieldId].default === 'template:user.messenger' && config.fields[fieldId]?.type === 'messenger') {
                if (isEmptyMessengerData(userState?.user?.messenger)) {
                    if (getTelegramUserName()) {
                        initContent[fieldId] = {
                            telegram: {
                                username: getTelegramUserName()
                            }
                        }
                    }
                    else {
                        initContent[fieldId] = isEmptyMessengerData(userState?.user?.messenger)
                    }
                }
                else {
                    initContent[fieldId] = userState?.user?.messenger
                }
            }

            else if (config.fields[fieldId].default === 'template:user.telegram.id') {
                if (userState?.user?.telegram_id) {
                    initContent[fieldId] = userState?.user?.telegram_id
                }
                else if (getTelegramUserId()){
                    initContent[fieldId] = getTelegramUserId()
                }
            }

            else if (config.fields[fieldId].default === 'template:user.telegram.name') {
                if (getTelegramDisplayName()){
                    initContent[fieldId] = getTelegramDisplayName()
                }
            }

            else if (config.fields[fieldId].default === 'template:user.pname') {
                initContent[fieldId] = userState?.user?.pName
            }

            else if (config.fields[fieldId].default === 'template:user.email') {
                initContent[fieldId] = userState?.user?.email
            }
        }
    })

    // setup default values on create
    if (!isEditMode && config?.defaultValues) {
        Object.keys(config.defaultValues).map(fieldId => {
            initContent[fieldId] = config.defaultValues[fieldId];
        })
    }

    return initContent;
}
